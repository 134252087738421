import "../../../css/table-filtering.css";
import { useMemo } from "react";
import { LinearProgress } from "@mui/material";
import { useTable, useFilters, usePagination } from "react-table";
import BTable from "react-bootstrap/Table";
import Search from "./Search";
import Control from "./Control";

const DataTable = ({
  columns,
  records,
  searchable = false,
  tableState,
  setTableState,
  loading,
}) => {
  const data = useMemo(() => records, [records]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1, pageSize: tableState.pageSize },
      manualPagination: true,
      pageCount: tableState.totalPages,
    },
    useFilters,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div className="table-responsive">
      {searchable && (
        <Search tableState={tableState} setTableState={setTableState} />
      )}
      {loading ? (
        <LinearProgress color="inherit" className="mx-2 mt-4" />
      ) : (
        <div>
          <BTable hover size="sm" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </BTable>
          <Control tableState={tableState} setTableState={setTableState} />
        </div>
      )}
    </div>
  );
};

export default DataTable;
