import { HTTP, HTTP_UN_INTERCEPTED } from "../config/http-axios.js";

const HTTP_GET = "GET";
const HTTP_POST = "POST";
const HTTP_PUT = "PUT";
const HTTP_PATCH = "PATCH";
const HTTP_DELETE = "DELETE";

class ApiBaseService {
  static request(
    endpoint,
    method,
    payload,
    config = { headers: {} },
    isUnIntercepted
  ) {
    if (config.headers["Content-Type"] === undefined) {
      config.headers["Content-Type"] = "application/json";
    }

    const http = isUnIntercepted ? HTTP_UN_INTERCEPTED : HTTP;

    return new Promise(async (resolve, reject) => {
      try {
        let response;
        switch (method) {
          case HTTP_GET:
            response = await http.get(endpoint, config);
            break;
          case HTTP_POST:
            response = await http.post(endpoint, payload, config);
            break;
          case HTTP_PUT:
            response = await http.put(endpoint, payload, config);
            break;
          case HTTP_PATCH:
            response = await http.patch(endpoint, payload, config);
            break;
          case HTTP_DELETE:
            response = await http.delete(endpoint, config);
            break;
          default:
            console.error("Wrong HTTP method requested");
        }
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default ApiBaseService;
