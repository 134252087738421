import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AccountService from "../../../../services/AccountService";
import RegexUtils from "../../../../utils/Regex";

const AddUserModal = ({ show, showHideHandler, userRoles }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const addAccountUserAPI = useAPI(
    AccountService.addAccountUser,
    addAccountUserHandler
  );

  function addAccountUser(formData) {
    addAccountUserAPI.execute(formData);
  }

  /* API success handlers */

  function addAccountUserHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add User</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(addAccountUser)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="emailInput">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="emailInput"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: RegexUtils.EMAIL,
                      message: "Email is invalid",
                    },
                  })}
                />
                <InputErrorMessage name="email" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="projectInput">
                  Role
                </label>
                <select
                  className="form-control"
                  id="roleInput"
                  {...register("role", {
                    required: "Role is required",
                  })}
                >
                  {userRoles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                <InputErrorMessage name="role" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || addAccountUserAPI.status === "pending"}
                >
                  {addAccountUserAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      ADD
                    </>
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
