const AppConstants = {
  passwordConstraints:
    "Password should contain at least 1 capital letter, small letter, digit, and special character. Minimum length is 8 characters.",
  kbMediaSupportedFiles: [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "webp",
    "webm",
    "mp4",
  ],
  invalidKbWebUrlExtensions: [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "svg",
    "bmp",
    "tiff",
    "webp", // Images
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx", // Documents
    "txt",
    "rtf",
    "csv",
    "json",
    "xml",
    "yml",
    "yaml", // Text and data files
    "zip",
    "rar",
    "7z",
    "tar",
    "gz", // Archives
    "mp3",
    "wav",
    "ogg",
    "mp4",
    "avi",
    "mkv",
    "mov", // Audio and video
    "exe",
    "dll",
    "bin",
    "iso", // Executable and system files
  ],
};

export default AppConstants;
