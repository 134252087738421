import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Authenticator from "../../services/Authenticator";
import logo from "../../images/logo-full.png";

const AuthHome = () => {
  let navigate = useNavigate();

  const checkIsAuthenticated = async () => {
    if (await Authenticator.isAuthenticated()) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkIsAuthenticated();
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
    >
      <div>
        <div id="auth-header">
          <Link to={process.env.REACT_APP_LANDING_URL}>
            <img src={logo} alt="TymBot logo" />
          </Link>
        </div>
        <div className="container my-3">
          <Outlet />
        </div>
        <div className="py-2">
          <p className="mb-0 text-center">
            Copyright © Designed &amp; Developed by <strong>Apnatomy</strong>{" "}
            2023
          </p>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default AuthHome;
