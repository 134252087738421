import Cookies from "universal-cookie";
import { EventSourcePolyfill } from "event-source-polyfill";

class SSEClient {
  constructor() {
    this.cookies = new Cookies();
    this.eventSource = null;
  }

  connect(url) {
    this.eventSource = new EventSourcePolyfill(url, {
      headers: {
        Account: `${this.cookies.get("account")}`,
        Authorization: `Bearer ${this.cookies.get("access_token")}`,
      },
    });
  }

  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  onMessage(callback) {
    if (this.eventSource) {
      this.eventSource.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.isHeartbeat) return; // skip calling the callback function if its a hearbeat message
        callback(data);
      });
    }
  }

  onError(callback) {
    if (this.eventSource) {
      this.eventSource.addEventListener("error", (event) => {
        callback(event);
      });
    }
  }
}

export default SSEClient;
