import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

export const COLUMNS = ({ handleBlock, handleUnblock }) => {
  return [
    {
      id: "sno",
      Header: "",
      accessor: "_id",
      Cell: ({ row }) => {
        return row.index + 1;
      },
      //disableFilters: true,
    },
    {
      Header: "Name",
      accessor: "fullName",
      Cell: ({ row }) => {
        const user = row.original;
        return <Tooltip title={user._id}>{user.fullName}</Tooltip>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      id: "account",
      Header: "Account",
      accessor: "_id",
      Cell: ({ value }) => {
        return (
          <Link
            to={`/administration/accounts?userId=${value}`}
            style={{ textDecoration: "underline" }}
          >
            view
          </Link>
        );
      },
    },
    {
      id: "action",
      Header: "",
      accessor: "_id",
      Cell: ({ row }) => {
        const user = row.original;

        return (
          <div className="d-flex">
            <BlockUserButton
              user={user}
              handleBlock={handleBlock}
              handleUnblock={handleUnblock}
            />
          </div>
        );
      },
    },
  ];
};

function BlockUserButton({ user, handleBlock, handleUnblock }) {
  const { _id, isBlocked } = user;

  const tooltipTitle = isBlocked ? "Unblock User" : "Block User";
  const buttonColorClass = isBlocked ? "btn-danger" : "btn-outline-warning";
  const btnIcon = isBlocked ? "fa-circle" : "fa-ban";

  function handleClick() {
    if (isBlocked) handleUnblock(_id);
    else handleBlock(_id);
  }

  return (
    <Tooltip title={tooltipTitle}>
      <button
        type="button"
        className={`btn ${buttonColorClass} shadow btn-xs sharp mr-2`}
        onClick={handleClick}
      >
        <i className={`fa ${btnIcon}`}></i>
      </button>
    </Tooltip>
  );
}
