import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LinearProgress, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";

const MonitorUsageModal = ({ show, showHideHandler, accountId }) => {
  const [accountUsage, setAccountUsage] = useState({
    stats: {
      chatbots: "",
      messages: "",
      projects: "",
    },
    currentMonth: {
      usage: {
        queries: "",
        knowledgeBaseCharacters: "",
      },
      limit: {
        queries: "",
        extraQueries: "",
        knowledgeBaseCharacters: "",
      },
    },
  });

  const isEnterpriseSubscPlan = accountUsage.currentMonth.limit.queries === -1;

  const getAccountUsageAPI = useAPI(
    AdminService.getAccountUsage,
    getAccountUsageHandler
  );

  function getAccountUsage() {
    getAccountUsageAPI.execute(accountId);
  }

  useEffect(() => {
    if (accountId) getAccountUsage();
  }, [accountId]);

  /* API success handlers */

  function getAccountUsageHandler(response) {
    setAccountUsage(response.data);
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
      size="lg"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Account Usage</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {getAccountUsageAPI.status === "pending" ? (
              <LinearProgress color="inherit" className="mx-2 mt-4" />
            ) : (
              <>
                <div className="m-3 text-center">
                  <div className="row">
                    <div className="col-4 py-4 card">
                      <h3 className="mb-1 text-primary">
                        {accountUsage.stats.projects}
                      </h3>
                      <span>Projects</span>
                    </div>
                    <div className="col-4 py-4 card">
                      <h3 className="mb-1 text-secondary">
                        {accountUsage.stats.chatbots}
                      </h3>
                      <span>Chatbots</span>
                    </div>
                    <div className="col-4 py-4 card">
                      <h3 className="mb-1 text-info">
                        {accountUsage.stats.messages}
                      </h3>
                      <span>Messages</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="widget-stat card">
                      <div className="card-body p-4">
                        <h3 className="card-title">Total Chatbot Queries</h3>
                        <h3 className="text-success">
                          {accountUsage.currentMonth.usage.queries}
                        </h3>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar progress-animated bg-warning"
                            style={{
                              width: `${getUsagePercentage(
                                accountUsage.currentMonth.usage.queries,
                                accountUsage.currentMonth.limit.queries +
                                  accountUsage.currentMonth.limit.extraQueries
                              )}%`,
                            }}
                          ></div>
                        </div>
                        <small>
                          {isEnterpriseSubscPlan ? (
                            <AllInclusiveIcon />
                          ) : accountUsage.currentMonth.limit.queries -
                              accountUsage.currentMonth.usage.queries >
                            0 ? (
                            accountUsage.currentMonth.limit.queries -
                            accountUsage.currentMonth.usage.queries
                          ) : (
                            0
                          )}{" "}
                          queries remaining this month{" "}
                          {!isEnterpriseSubscPlan ? (
                            <>
                              + {accountUsage.currentMonth.limit.extraQueries}{" "}
                              extra queries
                            </>
                          ) : null}
                        </small>
                        <Tooltip title="Queries made accross all chatbots in the current month">
                          <InfoOutlinedIcon
                            className="card-info-icon"
                            fontSize="small"
                            color="disabled"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="widget-stat card">
                      <div className="card-body p-4">
                        <h3 className="card-title">Total KB Characters Used</h3>
                        <h3 className="text-success">
                          {
                            accountUsage.currentMonth.usage
                              .knowledgeBaseCharacters
                          }
                        </h3>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar progress-animated bg-primary"
                            style={{
                              width: `${getUsagePercentage(
                                accountUsage.currentMonth.usage
                                  .knowledgeBaseCharacters,
                                accountUsage.currentMonth.limit
                                  .knowledgeBaseCharacters
                              )}%`,
                            }}
                          ></div>
                        </div>
                        <small>
                          {accountUsage.currentMonth.limit
                            .knowledgeBaseCharacters === -1 ? (
                            <AllInclusiveIcon />
                          ) : (
                            accountUsage.currentMonth.limit
                              .knowledgeBaseCharacters -
                            accountUsage.currentMonth.usage
                              .knowledgeBaseCharacters
                          )}{" "}
                          characters left
                        </small>
                        <Tooltip title="Aggregate of all knowledgebase characters">
                          <InfoOutlinedIcon
                            className="card-info-icon"
                            fontSize="small"
                            color="disabled"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

function getUsagePercentage(used, limit) {
  let percentage = (used / limit) * 100;
  return Math.floor(percentage);
}

export default MonitorUsageModal;
