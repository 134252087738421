import Labels from "../constants/Labels";

function getPriceBasedOnCurrency(price, customerCurrency) {
  const currencySymbol = customerCurrency === "INR" ? "₹" : "$";
  return `${currencySymbol}${price.pricePerMonth}`;
}

function getPlansBasedOnGPTModel(subscriptionPlans, isAdvancedModelSelected) {
  // Deep copy using JSON.stringify and JSON.parse
  const copiedPlans = JSON.parse(JSON.stringify(subscriptionPlans));

  let filteredPlans = [];
  if (isAdvancedModelSelected) {
    filteredPlans = copiedPlans.filter((plan) =>
      plan.services.includes(Labels.GPT_ADVANCED_MODEL)
    );
  } else {
    filteredPlans = copiedPlans.filter((plan) =>
      plan.services.includes(Labels.GPT_BASIC_MODEL)
    );
  }

  // remove the '(with GPT...)' text from plan name
  filteredPlans.map((plan) => {
    plan.name = removeModelNameFromPlanName(plan.name);
  });

  return filteredPlans;
}

function removeModelNameFromPlanName(planName) {
  return planName.replace(/\(with (GPT-4|GPT-3.5) Turbo\)/g, "");
}

function isAccountSubscriptionExist(accountSubscription) {
  return (
    accountSubscription.status !== "" && accountSubscription.status !== "void"
  );
}

const SubscriptionUtils = {
  getPriceBasedOnCurrency,
  getPlansBasedOnGPTModel,
  removeModelNameFromPlanName,
  isAccountSubscriptionExist,
};

export default SubscriptionUtils;
