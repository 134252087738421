import ApiBaseService from "./ApiBaseService";

class AssistantService {
  static getAssistants() {
    return ApiBaseService.request("/control/assistants", "GET");
  }

  static createAssistant([data]) {
    return ApiBaseService.request(`/control/assistants`, "POST", data);
  }

  static getAssistant([assistantId]) {
    return ApiBaseService.request(`/control/assistants/${assistantId}`, "GET");
  }

  static updateAssistant([assistantId, data]) {
    return ApiBaseService.request(
      `/control/assistants/${assistantId}`,
      "PUT",
      data
    );
  }

  static deleteAssistant([assistantId]) {
    return ApiBaseService.request(
      `/control/assistants/${assistantId}`,
      "DELETE"
    );
  }
}

export default AssistantService;
