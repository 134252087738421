import { useEffect, useState } from "react";
import DataTable from "../../../../utils/DataTable/index.js";
import { COLUMNS } from "./Columns.js";
import { useSearchParams } from "react-router-dom";
import useAPI from "../../../../../hooks/useAPI.js";
import AdminService from "../../../../../services/AdminService.js";
import ConfirmActionModal from "../../../../utils/Modals/ConfirmActionModal";
import PlansModal from "../PlansModal.js";

const SubscriptionList = () => {
  const [searchParams] = useSearchParams();
  const [accounts, setAccounts] = useState([]);

  const [showPlansModal, setShowPlansModal] = useState(false);
  const [modifySubscModalData, setModifySubscModalData] = useState("");

  const [showCancelSubscModal, setShowCancelSubscModal] = useState(false);
  const [cancelSubscModalData, setCancelSubscModalData] = useState("");

  const [tableState, setTableState] = useState({
    totalPages: 0,
    page: 1,
    pageSize: 10,
    hasPrevPage: false,
    hasNextPage: false,
  });

  const getSubscriptionsAPI = useAPI(
    AdminService.getSubscriptions,
    getSubscriptionsHandler
  );

  const getSubscriptionsBySearchAPI = useAPI(
    AdminService.getSubscriptionsBySearch,
    getSubscriptionsHandler
  );

  const cancelSubscriptionAPI = useAPI(
    AdminService.cancelSubscription,
    cancelSubscriptionHandler
  );

  function getSubscriptions() {
    if (searchParams.has("search")) {
      getSubscriptionsBySearchAPI.execute(
        searchParams.get("search"),
        tableState.page,
        tableState.pageSize
      );
    } else {
      getSubscriptionsAPI.execute(tableState.page, tableState.pageSize);
    }
  }

  function cancelSubscription(id) {
    cancelSubscriptionAPI.execute(id);
  }

  function handleModifySubscription(id) {
    setShowPlansModal(true);
    setModifySubscModalData(id);
  }

  function handleCancelSubscription(id) {
    setShowCancelSubscModal(true);
    setCancelSubscModalData(id);
  }

  useEffect(() => {
    getSubscriptions();
  }, [searchParams, tableState.page, tableState.pageSize]);

  /* API success handlers */

  function getSubscriptionsHandler(response) {
    let { docs, totalPages, page, limit, hasPrevPage, hasNextPage } =
      response.data;

    setTableState({
      totalPages,
      page,
      pageSize: limit,
      hasPrevPage,
      hasNextPage,
    });

    setAccounts(docs);
  }

  function cancelSubscriptionHandler(response) {
    setShowCancelSubscModal(false);
    getSubscriptions();
  }

  return (
    <>
      <DataTable
        columns={COLUMNS({
          handleModifySubscription,
          handleCancelSubscription,
        })}
        records={accounts}
        searchable
        tableState={tableState}
        setTableState={setTableState}
        loading={
          getSubscriptionsAPI.status === "pending" ||
          getSubscriptionsBySearchAPI.status === "pending"
        }
      />
      <PlansModal
        subscriptionId={modifySubscModalData}
        show={showPlansModal}
        showHideHandler={setShowPlansModal}
      />
      <ConfirmActionModal
        title="Confirm canceling the subscription?"
        description="The subscription will be set to be canceled and will no longer collect payments for the account."
        show={showCancelSubscModal}
        confirmActionHandler={() => {
          cancelSubscription(cancelSubscModalData);
        }}
        isActionPending={cancelSubscriptionAPI.status === "pending"}
        showHideHandler={setShowCancelSubscModal}
      />
    </>
  );
};

export default SubscriptionList;
