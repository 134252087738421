import { Card } from "react-bootstrap";
import AccountList from "./List";

const Account = () => {
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <span>Account Management</span>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <AccountList />
        </Card.Body>
      </Card>
    </>
  );
};

export default Account;
