import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import debounce from "../../../utils/Debounce";

const Search = ({ tableState, setTableState }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideReset, setHideReset] = useState(true);
  const searchInputRef = useRef();

  const handleInputChange = debounce((e) => {
    const value = e.target.value;
    applySearchFilter(value);
  }, 500);

  function applySearchFilter(value) {
    searchParams.search = value;
    setSearchParams({ ...searchParams });
  }

  function resetFilters() {
    setTableState({
      ...tableState,
      pageSize: 10,
    });
    setSearchParams({});
    checkFilterApplied();
    searchInputRef.current.value = "";
  }

  function checkFilterApplied() {
    let isSearchParamEmpty = searchParams.size === 0;
    setHideReset(isSearchParamEmpty);

    // pre-fill the search input
    if (!isSearchParamEmpty) {
      searchInputRef.current.value = searchParams.has("userId")
        ? searchParams.get("userId")
        : searchParams.get("search");
    }
  }

  useEffect(() => {
    checkFilterApplied();
  }, [searchParams]);

  return (
    <div className="w-100 p-2 d-flex flex-row justify-content-between">
      <input
        className="mt-0 input-search form-control"
        onKeyUp={handleInputChange}
        style={{ width: "20%" }}
        placeholder="Search"
        ref={searchInputRef}
      />
      <span>
        {hideReset ? null : (
          <Button
            variant="outline-secondary light btn-rounded btn-xxs"
            onClick={resetFilters}
          >
            <span>
              <i className="fa fa-refresh color-secondary" />
            </span>{" "}
            Reset
          </Button>
        )}
      </span>
    </div>
  );
};

export default Search;
