import { useEffect, useState } from "react";
import DataTable from "../../../../utils/DataTable";
import { COLUMNS } from "./Columns.js";
import { useSearchParams } from "react-router-dom";
import useAPI from "../../../../../hooks/useAPI";
import AdminService from "../../../../../services/AdminService";
import ConfirmActionModal from "../../../../utils/Modals/ConfirmActionModal";
import ViewChatbotsModal from "../ViewChatbotsModal.js";
import ChangeOwnerModal from "../ChangeOwnerModal.js";
import MonitorUsageModal from "../MonitorUsageModal.js";

const AccountList = () => {
  const [searchParams] = useSearchParams();
  const [accounts, setAccounts] = useState([]);

  const [focuedAccountId, setFocusedAccountId] = useState("");

  const [showMonitorUsageModal, setShowMonitorUsageModal] = useState(false);

  const [showViewChatbotsModal, setShowViewChatbotsModal] = useState(false);

  const [showRequestAccAccessModal, setShowRequestAccAccessModal] =
    useState(false);

  const [showSuspendAccModal, setShowSuspendAccModal] = useState(false);

  const [showUnsuspendAccModal, setShowUnsuspendAccModal] = useState(false);

  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);

  const [showDeleteAccModal, setShowDeleteAccModal] = useState(false);

  const [tableState, setTableState] = useState({
    totalPages: 0,
    page: 1,
    pageSize: 10,
    hasPrevPage: false,
    hasNextPage: false,
  });

  const getAccountsAPI = useAPI(AdminService.getAccounts, getAccountsHandler);

  const getAccountsBySearchAPI = useAPI(
    AdminService.getAccountsBySearch,
    getAccountsHandler
  );

  const getAccountsByUserIdAPI = useAPI(
    AdminService.getAccountsByUserId,
    getAccountsHandler
  );

  const requestAccAccessAPI = useAPI(
    AdminService.requestAccountAccess,
    requestAccountAccessHandler,
    true
  );

  const suspendAccountAPI = useAPI(
    AdminService.suspendAccount,
    suspendAccountHandler
  );

  const unsuspendAccountAPI = useAPI(
    AdminService.unsuspendAccount,
    unsuspendAccountHandler
  );

  const deleteAccountAPI = useAPI(
    AdminService.deleteAccount,
    deleteAccountHandler
  );

  function getAccounts() {
    if (searchParams.has("userId")) {
      getAccountsByUserIdAPI.execute(searchParams.get("userId"));
    } else if (searchParams.has("search")) {
      getAccountsBySearchAPI.execute(
        searchParams.get("search"),
        tableState.page,
        tableState.pageSize
      );
    } else {
      getAccountsAPI.execute(tableState.page, tableState.pageSize);
    }
  }

  function requestAccAccess(id) {
    requestAccAccessAPI.execute(id);
  }

  function suspendAccount(id) {
    suspendAccountAPI.execute(id);
  }

  function unsuspendAccount(id) {
    unsuspendAccountAPI.execute(id);
  }

  function deleteAccount(id) {
    deleteAccountAPI.execute(id);
  }

  function handleMonitorUsage(id) {
    setShowMonitorUsageModal(true);
    setFocusedAccountId(id);
  }

  function handleViewChatbots(id) {
    setShowViewChatbotsModal(true);
    setFocusedAccountId(id);
  }

  function handleRequestAccAccess(id) {
    setShowRequestAccAccessModal(true);
    setFocusedAccountId(id);
  }

  function handleSuspend(id) {
    setShowSuspendAccModal(true);
    setFocusedAccountId(id);
  }

  function handleUnsuspend(id) {
    setShowUnsuspendAccModal(true);
    setFocusedAccountId(id);
  }

  function handleChangeOwner(id) {
    setShowChangeOwnerModal(true);
    setFocusedAccountId(id);
  }

  function handleDelete(id) {
    setShowDeleteAccModal(true);
    setFocusedAccountId(id);
  }

  useEffect(() => {
    getAccounts();
  }, [searchParams, tableState.page, tableState.pageSize]);

  /* API success handlers */

  function getAccountsHandler(response) {
    let { docs, totalPages, page, limit, hasPrevPage, hasNextPage } =
      response.data;

    setTableState({
      totalPages,
      page,
      pageSize: limit,
      hasPrevPage,
      hasNextPage,
    });

    setAccounts(docs);
  }

  function requestAccountAccessHandler(response) {
    setShowRequestAccAccessModal(false);
  }

  function suspendAccountHandler(response) {
    setShowSuspendAccModal(false);
    getAccounts();
  }

  function unsuspendAccountHandler(response) {
    setShowUnsuspendAccModal(false);
    getAccounts();
  }

  function deleteAccountHandler(response) {
    setShowDeleteAccModal(false);
    getAccounts();
  }

  return (
    <>
      <DataTable
        columns={COLUMNS({
          handleMonitorUsage,
          handleViewChatbots,
          handleRequestAccAccess,
          handleSuspend,
          handleUnsuspend,
          handleChangeOwner,
          handleDelete,
        })}
        records={accounts}
        searchable
        tableState={tableState}
        setTableState={setTableState}
        loading={
          getAccountsAPI.status === "pending" ||
          getAccountsBySearchAPI.status === "pending" ||
          getAccountsByUserIdAPI.status === "pending"
        }
      />
      <MonitorUsageModal
        show={showMonitorUsageModal}
        showHideHandler={setShowMonitorUsageModal}
        accountId={focuedAccountId}
      />
      <ConfirmActionModal
        title="Request account owner to grant you access?"
        description="An email will be sent to owner to confirm granting you access to their account as a member."
        show={showRequestAccAccessModal}
        confirmActionHandler={() => {
          requestAccAccess(focuedAccountId);
        }}
        isActionPending={requestAccAccessAPI.status === "pending"}
        showHideHandler={setShowRequestAccAccessModal}
      />
      <ConfirmActionModal
        title="Confirm suspending this account?"
        description="None of the account users will be able to access their resources upon suspending."
        show={showSuspendAccModal}
        confirmActionHandler={() => {
          suspendAccount(focuedAccountId);
        }}
        isActionPending={suspendAccountAPI.status === "pending"}
        showHideHandler={setShowSuspendAccModal}
      />
      <ConfirmActionModal
        title="Confirm unsuspending this account?"
        description="Account users will be able to access their resources upon unsusending."
        show={showUnsuspendAccModal}
        confirmActionHandler={() => {
          unsuspendAccount(focuedAccountId);
        }}
        isActionPending={unsuspendAccountAPI.status === "pending"}
        showHideHandler={setShowUnsuspendAccModal}
      />
      <ConfirmActionModal
        title="Confirm deleting this account?"
        description="All of the account data such as projects, chatbots, knowledgebases, conversations, integrations, usage, and subscriptions will be deleted and active subscription will be canceled if present."
        show={showDeleteAccModal}
        confirmActionHandler={() => {
          deleteAccount(focuedAccountId);
        }}
        isActionPending={deleteAccountAPI.status === "pending"}
        showHideHandler={setShowDeleteAccModal}
      />
      <ViewChatbotsModal
        show={showViewChatbotsModal}
        showHideHandler={setShowViewChatbotsModal}
        accountId={focuedAccountId}
      />

      <ChangeOwnerModal
        show={showChangeOwnerModal}
        showHideHandler={setShowChangeOwnerModal}
        accountId={focuedAccountId}
      />
    </>
  );
};

export default AccountList;
