const SHOW_ROWS = [10, 25, 50];

const Control = ({ tableState, setTableState }) => {
  function nextPage() {
    setTableState({
      ...tableState,
      page: tableState.page + 1,
    });
  }

  function previousPage() {
    setTableState({
      ...tableState,
      page: tableState.page - 1,
    });
  }

  function goToPage(page) {
    setTableState({
      ...tableState,
      page: page,
    });
  }

  return (
    <div className="px-2 py-3">
      <div className="d-flex justify-content-between">
        <span>
          Page{" "}
          <strong>
            {tableState.page} of {tableState.totalPages}
          </strong>
        </span>
        <span className="table-page-size">
          Show :{" "}
          <select
            value={tableState.pageSize}
            onChange={(e) => {
              setTableState({
                ...tableState,
                page: 1,
                pageSize: Number(e.target.value),
              });
            }}
          >
            {SHOW_ROWS.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </span>
        <span className="table-index">
          Go to page :{" "}
          <input
            type="number"
            className="ml-2"
            defaultValue={tableState.page}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value) : 0;
              goToPage(pageNumber);
            }}
          />
        </span>
      </div>
      <div className="text-center">
        <div className="filter-pagination mt-4">
          <button
            className=" previous-button"
            onClick={() => goToPage(1)}
            disabled={!tableState.hasPrevPage}
          >
            {"<<"}
          </button>

          <button
            className="previous-button"
            onClick={() => previousPage()}
            disabled={!tableState.hasPrevPage}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => nextPage()}
            disabled={!tableState.hasNextPage}
          >
            Next
          </button>
          <button
            className=" next-button"
            onClick={() => goToPage(tableState.totalPages)}
            disabled={!tableState.hasNextPage}
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Control;
