import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Authenticator from "../../../services/Authenticator";
import AccountService from "../../../services/AccountService";
import useAPI from "../../../hooks/useAPI";
import RegexUtils from "../../../utils/Regex";
import withState from "../../utils/withState";

const Header = ({ toggle, onProfile, reduxState }) => {
  const { pathname } = useLocation();
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    let paths = pathname.split("/");
    if (RegexUtils.DIGIT.test(paths[paths.length - 1])) {
      // if endpoint is "id", then pop last element to use the previous param
      paths.pop();
    }

    var name = paths[paths.length - 1].split("-");
    name = name.join(" ") === "" ? "Dashboard" : name.join(" ");

    setPageName(name);
  }, [pathname]);

  const [userRole, setUserRole] = useState("");

  useAPI(AccountService.getUserAccount, getUserAccountHandler, false, true);

  /* API Success Handlers */

  function getUserAccountHandler(response) {
    setUserRole(response.data.role);
  }

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {pageName}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li
                className={`nav-item dropdown header-profile ${
                  toggle === "profile" ? "show" : ""
                }`}
                onClick={() => onProfile()}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <div className="d-flex flex-row align-items-center">
                      <div className="pr-2">
                        <span className="text-black">
                          {reduxState.authUser.fullName}
                        </span>
                        <p className="fs-12 mb-0">{userRole}</p>
                      </div>
                      <KeyboardArrowDownIcon size="small" />
                    </div>
                  </div>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  <Link to="/profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                  <Link
                    className="dropdown-item ai-icon"
                    onClick={() => {
                      Authenticator.logout();
                    }}
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    <span className="ml-2">Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default withState(Header);
