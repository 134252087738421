import React, { useEffect, useState } from "react";
import DataTable from "../../../../utils/DataTable/index.js";
import { COLUMNS } from "./Columns.js";
import { useSearchParams } from "react-router-dom";
import ConfirmActionModal from "../../../../utils/Modals/ConfirmActionModal.js";

import useAPI from "../../../../../hooks/useAPI.js";
import AdminService from "../../../../../services/AdminService.js";

import EditAnnModal from "./EditAnnModal.js";

const AnnouncementList = () => {
  const [searchParams] = useSearchParams();
  const [announcements, setAnnouncements] = useState([]);

  const [showEditAnnouncementModal, setShowEditAnnouncementModal] =
    useState(false);
  const [showDeleteAnnouncementModal, setShowDeleteAnnouncementModal] =
    useState(false);
  const [editAnnouncementModalData, setEditAnnouncementModalData] =
    useState("");
  const [deleteAnnouncementModalData, setDeleteAnnouncementModalData] =
    useState("");

  const [tableState, setTableState] = useState({
    totalPages: 0,
    page: 1,
    pageSize: 10,
    hasPrevPage: false,
    hasNextPage: false,
  });

  // APIs

  const getAnnouncementsAPI = useAPI(
    AdminService.getAnnouncements,
    getAnnouncementsHandler
  );

  const getAnnouncementsBySearchAPI = useAPI(
    AdminService.getAnnouncementsBySearch,
    getAnnouncementsHandler
  );

  const deleteAnnouncementAPI = useAPI(
    AdminService.deleteAnnouncement,
    deleteAnnouncementHandler
  );

  function getAnnouncements() {
    if (searchParams.has("search")) {
      getAnnouncementsBySearchAPI.execute(
        searchParams.get("search"),
        tableState.page,
        tableState.pageSize
      );
    } else {
      getAnnouncementsAPI.execute(tableState.page, tableState.pageSize);
    }
  }

  function deleteAnnouncementHandler(response) {
    setShowDeleteAnnouncementModal(false);
    getAnnouncements();
  }

  // Handlers

  function getAnnouncementsHandler(response) {
    let { docs, totalPages, page, limit, hasPrevPage, hasNextPage } =
      response.data;

    setTableState({
      totalPages,
      page,
      pageSize: limit,
      hasPrevPage,
      hasNextPage,
    });

    setAnnouncements(docs);
  }

  function handleEditAnnouncement(id) {
    setShowEditAnnouncementModal(true);
    const announcement = announcements.find(
      (announcement) => announcement._id === id
    );
    setEditAnnouncementModalData(announcement);
  }

  function handleDeleteAnnouncement(id) {
    setShowDeleteAnnouncementModal(true);
    setDeleteAnnouncementModalData(id);
  }

  // Effects
  useEffect(() => {
    getAnnouncements();
  }, [searchParams, tableState.page, tableState.pageSize]);

  return (
    <>
      <DataTable
        columns={COLUMNS({
          handleEditAnnouncement,
          handleDeleteAnnouncement,
        })}
        records={announcements}
        searchable
        tableState={tableState}
        setTableState={setTableState}
        loading={
          getAnnouncementsAPI.status === "pending" ||
          getAnnouncementsBySearchAPI.status === "pending"
        }
      />

      <ConfirmActionModal
        title="Confirm deleting this announcement?"
        description="The announcement will be deleted and cannot be recovered."
        show={showDeleteAnnouncementModal}
        confirmActionHandler={() => {
          deleteAnnouncementAPI.execute(deleteAnnouncementModalData);
        }}
        isActionPending={deleteAnnouncementAPI.status === "pending"}
        showHideHandler={setShowDeleteAnnouncementModal}
      />
      <EditAnnModal
        show={showEditAnnouncementModal}
        showHideHandler={setShowEditAnnouncementModal}
        data={editAnnouncementModalData}
      />
    </>
  );
};

export default AnnouncementList;
