import ApiBaseService from "./ApiBaseService";

class AuthService {
  static login([data, captchaToken]) {
    const headers = {
      "captcha-token": captchaToken,
    };
    return ApiBaseService.request(
      "/auth/control-login",
      "POST",
      data,
      { headers },
      true
    );
  }

  static sendOTP(data, captchaToken) {
    const headers = {
      "captcha-token": captchaToken,
    };
    return ApiBaseService.request(
      "/auth/otp-request",
      "POST",
      data,
      { headers },
      true
    );
  }

  static verifyOTP(data, captchaToken) {
    const headers = {
      "captcha-token": captchaToken,
    };
    return ApiBaseService.request(
      "/auth/otp-verify",
      "POST",
      data,
      { headers },
      true
    );
  }

  static forgotPassword(data, captchaToken) {
    const headers = {
      "captcha-token": captchaToken,
    };
    return ApiBaseService.request(
      "/auth/forgot-password",
      "POST",
      data,
      { headers },
      true
    );
  }

  static resetPassword([data]) {
    return ApiBaseService.request("/auth/reset-password", "POST", data);
  }
}

export default AuthService;
