import { Badge } from "react-bootstrap";
import TimeAgo from "react-timeago";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import SubscriptionUtils from "../../../../../utils/Subscription";

export const COLUMNS = ({
  handleMonitorUsage,
  handleViewChatbots,
  handleRequestAccAccess,
  handleSuspend,
  handleUnsuspend,
  handleChangeOwner,
  handleDelete,
}) => {
  return [
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        return row.index + 1;
      },
      //disableFilters: true,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        const account = row.original;
        return <Tooltip title={account._id}>{account.name}</Tooltip>;
      },
    },
    {
      Header: "Owner",
      accessor: "owner",
      Cell: ({ value }) => {
        return (
          <Link
            to={`/administration/users?userId=${value._id}`}
            style={{ textDecoration: "underline" }}
          >
            {value.fullName}
          </Link>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => {
        return (
          <Badge
            variant={
              (value === "Personal" ? "primary" : "secondary") +
              " light badge-rounded badge-sm"
            }
          >
            {value}
          </Badge>
        );
      },
    },
    {
      Header: "Member Since",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return <TimeAgo date={value} />;
      },
    },
    {
      id: "subscriptionStatus",
      Header: "Subscription",
      accessor: "latestSubscription",
      Cell: ({ value }) => {
        const hasActiveSubscription =
          value?.status === "active" || value?.status === "trialing";
        return hasActiveSubscription ? (
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-success mr-1"></i> Active
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-danger mr-1"></i> Inactive
          </div>
        );
      },
    },
    {
      id: "subscriptionName",
      Header: "Subscription Plan",
      accessor: "latestSubscription",
      Cell: ({ value }) => {
        if (value) {
          return SubscriptionUtils.removeModelNameFromPlanName(
            value.subscriptionPlan.name
          );
        }
        return "";
      },
    },
    {
      Header: "",
      accessor: "_id",
      Cell: ({ row }) => {
        const account = row.original;

        return (
          <div className="d-flex justify-content-center">
            <Tooltip title="Monitor Usage">
              <button
                type="button"
                className="btn btn-outline-success shadow btn-xs sharp mr-2"
                onClick={() => handleMonitorUsage(account._id)}
                disabled={!account.latestSubscription}
              >
                <i className="fa fa-eye"></i>
              </button>
            </Tooltip>
            <Tooltip title="View Chatbots">
              <button
                type="button"
                className="btn btn-outline-secondary shadow btn-xs sharp mr-2"
                onClick={() => handleViewChatbots(account._id)}
              >
                <i className="fa fa-simplybuilt"></i>
              </button>
            </Tooltip>
            <Tooltip title="Request Account Access">
              <button
                type="button"
                className="btn btn-outline-primary shadow btn-xs sharp mr-2"
                onClick={() => handleRequestAccAccess(account._id)}
              >
                <i className="fa fa-sign-out"></i>
              </button>
            </Tooltip>
            <Tooltip title="Change Ownership">
              <button
                type="button"
                className="btn btn-outline-info shadow btn-xs sharp mr-2"
                onClick={() => handleChangeOwner(account._id)}
              >
                <i className="fa fa-user"></i>
              </button>
            </Tooltip>
            <SuspendActionButton
              account={account}
              handleSuspend={handleSuspend}
              handleUnsuspend={handleUnsuspend}
            />
            <Tooltip title="Delete Account">
              <button
                type="button"
                className="btn btn-outline-danger shadow btn-xs sharp"
                onClick={() => handleDelete(account._id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
};

function SuspendActionButton({ account, handleSuspend, handleUnsuspend }) {
  const { _id, isSuspended } = account;

  const tooltipTitle = isSuspended ? "Unsuspend Account" : "Suspend Account";
  const buttonColorClass = isSuspended ? "btn-danger" : "btn-outline-warning";
  const btnIcon = isSuspended ? "fa-circle" : "fa-ban";

  function handleClick() {
    if (isSuspended) handleUnsuspend(_id);
    else handleSuspend(_id);
  }

  return (
    <Tooltip title={tooltipTitle}>
      <button
        type="button"
        className={`btn ${buttonColorClass} shadow btn-xs sharp mr-2`}
        onClick={handleClick}
      >
        <i className={`fa ${btnIcon}`}></i>
      </button>
    </Tooltip>
  );
}
