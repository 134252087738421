import { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import UsersList from "./UsersList";
import AddUserModal from "./AddUserModal";

import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";

const Users = () => {
  const [modalShow, setModalShow] = useState(false);

  const [userRoles, setUserRoles] = useState([]);

  useAPI(
    AdminService.getSystemUserRoles,
    getSystemUserRolesHandler,
    false,
    true
  );

  function getSystemUserRolesHandler(response) {
    setUserRoles(response.data);
  }

  return (
    <>
      <div className="page-titles mb-1">
        <div className="d-flex justify-content-between flex-wrap">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link>Account</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={"#"}>Users</Link>
            </li>
          </ol>
          <div className="mt-2 mt-sm-0">
            <Button variant="primary" onClick={() => setModalShow(true)}>
              Add User
            </Button>
          </div>
        </div>
      </div>

      <Card>
        <Card.Header>
          <Card.Title>
            <span>User Management</span>
            <div className="text-dark text-nowrap mt-1 fs-16">
              Add, update, and archive your account users
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <UsersList userRoles={userRoles} />
        </Card.Body>
      </Card>
      <AddUserModal
        show={modalShow}
        showHideHandler={setModalShow}
        userRoles={userRoles}
      />
    </>
  );
};

export default Users;
