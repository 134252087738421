import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import useAPI from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../../utils/InputErrorMessage";
import TemplateService from "../../../../../services/TemplateService";
import AppConstants from "../../../../../constants/App";

const MediaContentModal = ({ show, showHideHandler, data }) => {
  if (Object.keys(data).length === 0) return null;
  if (data.knowledgeBase.resourceType !== "media") return null;

  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(data.knowledgeBase.resource.content);
  const [olderMediaAttachments, setOlderMediaAttachments] = useState([]);
  const [olderMediaAttachmentsToDelete, setOlderMediaAttachmentsToDelete] =
    useState([]);
  const [mediaAttachments, setMediaAttachments] = useState([]);

  const {
    register,
    formState: { errors, isValid },
    trigger,
    reset,
    getValues,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const updateKnowledgeBaseMediaAPI = useAPI(
    TemplateService.updateKnowledgeBaseMedia,
    updateKnowledgeBaseMediaHandler
  );

  const getKnowledgeBaseMediaFileAPI = useAPI(
    TemplateService.getKnowledgeBaseMediaFile,
    getKnowledgeBaseMediaFileHandler
  );

  async function updateKnowledgeBaseMedia() {
    const isFormValid = await trigger();
    if (!isFormValid) {
      return;
    }

    const formData = getValues();
    formData["attachments"] = mediaAttachments;
    formData["olderAttachmentsToDelete"] = olderMediaAttachmentsToDelete;

    updateKnowledgeBaseMediaAPI.execute(
      data.chatbotId,
      data.knowledgeBase._id,
      formData
    );
  }

  function getKnowledgeBaseMediaFile(fileId) {
    getKnowledgeBaseMediaFileAPI.execute(
      data.chatbotId,
      data.knowledgeBase._id,
      fileId
    );
  }

  /* API Success Handlers */

  function updateKnowledgeBaseMediaHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  function getKnowledgeBaseMediaFileHandler(response) {
    window.open(response.data, "_blank");
  }

  useEffect(() => {
    reset(data.knowledgeBase.resource);
    setContent(data.knowledgeBase.resource.content);
    setOlderMediaAttachments(data.knowledgeBase.resource.attachments);
  }, [data.knowledgeBase.resource]);

  return (
    <Modal
      className="fade bd-example-modal-lg modal-dialog-scrollable"
      show={show}
      onHide={() => showHideHandler(false)}
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Media Content</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => showHideHandler(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {isEditing ? (
          <>
            <div className="form-group">
              <label className="text-black font-w500">Content</label>
              <textarea
                cols="30"
                rows="5"
                className="form-control bg-transparent"
                name="content"
                id="contentInput"
                {...register("content", {
                  required: "Content is required",
                  minLength: {
                    value: 100,
                    message: "Minimum 100 characters",
                  },
                })}
              />
              <InputErrorMessage name="content" errors={errors} />
            </div>
            <div>
              <small>Older Attachments:</small>
              <div className="d-flex flex-wrap">
                {olderMediaAttachments.map((attachment, index) => {
                  return (
                    <Chip
                      key={`attachment-${index}`}
                      label={attachment.file.originalFilename}
                      variant="outlined"
                      className="mr-2 mb-2"
                      onClick={() => {
                        getKnowledgeBaseMediaFile(attachment.file._id);
                      }}
                      onDelete={() => {
                        setOlderMediaAttachments((prevState) => {
                          let index = prevState.findIndex(
                            (file) =>
                              file.file.originalFilename ===
                              attachment.file.originalFilename
                          );
                          prevState.splice(index, 1);
                          return [...prevState];
                        });

                        olderMediaAttachmentsToDelete.push(attachment);
                        setOlderMediaAttachmentsToDelete([
                          ...olderMediaAttachmentsToDelete,
                        ]);
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-2">
              <small>
                Supported attachment formats:{" "}
                {AppConstants.kbMediaSupportedFiles.join(", ")}
              </small>
              <div className="input-group custom_file_input mb-4">
                <div className="custom-file">
                  <input
                    type="file"
                    multiple
                    className="custom-file-input"
                    name="attachments"
                    id="attachmentsInput"
                    onChangeCapture={(e) => {
                      setMediaAttachments([
                        ...mediaAttachments,
                        ...e.target.files,
                      ]);
                    }}
                    accept={AppConstants.kbMediaSupportedFiles.reduce(
                      (builder, type) => {
                        return (builder += "." + type + ",");
                      },
                      ""
                    )}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="attachmentsInput"
                  >
                    Choose Attachments
                  </label>
                  <InputErrorMessage name="attachments" errors={errors} />
                </div>
              </div>
            </div>
            <div>
              <small>Attachments:</small>
              <div className="d-flex flex-wrap">
                {mediaAttachments.map((attachment, index) => {
                  return (
                    <Chip
                      key={`attachment-${index}`}
                      label={attachment.name}
                      variant="outlined"
                      className="mr-2 mb-2"
                      onDelete={() => {
                        setMediaAttachments((prevState) => {
                          let index = prevState.findIndex(
                            (file) => file.name === attachment.name
                          );
                          prevState.splice(index, 1);
                          return [...prevState];
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <p>{content}</p>
            </div>
            <div>
              <small>Attachments:</small>
              <div className="d-flex flex-wrap">
                {olderMediaAttachments.map((attachment, index) => {
                  return (
                    <Chip
                      key={`attachment-${index}`}
                      label={attachment.file.originalFilename}
                      variant="outlined"
                      className="mr-2 mb-2"
                      onClick={() => {
                        getKnowledgeBaseMediaFile(attachment.file._id);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <span>{content ? content.length : 0} characters</span>
        <div>
          {isEditing ? (
            <>
              <Button
                className="mr-2"
                onClick={() => {
                  setContent(data.knowledgeBase.resource.content);
                  setOlderMediaAttachments([
                    ...data.knowledgeBase.resource.attachments,
                  ]);
                  setOlderMediaAttachmentsToDelete([]);
                  setIsEditing(false);
                }}
                variant="danger light"
              >
                Cancel
              </Button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  !isValid || updateKnowledgeBaseMediaAPI.status === "pending"
                }
                onClick={
                  isValid && updateKnowledgeBaseMediaAPI.status !== "pending"
                    ? updateKnowledgeBaseMedia
                    : undefined
                }
              >
                {updateKnowledgeBaseMediaAPI.status === "pending" ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm text-light mr-2"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                    Save
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setIsEditing(true)}
            >
              Edit Content
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MediaContentModal;
