import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { LinearProgress, Tooltip } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";
import CopyChatbotModal from "./CopyChatbotModal.js";

const ViewChatbotsModal = ({ show, showHideHandler, accountId }) => {
  const [chatbots, setChatbots] = useState([]);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [showCopyChatbotModal, setShowCopyChatbotModal] = useState(false);

  const getAccountChatbotsAPI = useAPI(
    AdminService.getAccountChatbots,
    getAccountChatbotsHandler
  );

  function getAccountChatbots() {
    getAccountChatbotsAPI.execute(accountId);
  }

  /* API success handlers */

  function getAccountChatbotsHandler(response) {
    setChatbots(response.data);
  }

  function copyChatbot(chatbot) {
    setSelectedChatbot(chatbot);
    setShowCopyChatbotModal(true);
    showHideHandler(false);
  }

  useEffect(() => {
    if (accountId) getAccountChatbots();
  }, [accountId]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => showHideHandler(false)}
        className="modal fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>Chatbots</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getAccountChatbotsAPI.status === "pending" ? (
            <LinearProgress color="inherit" />
          ) : chatbots.length === 0 ? (
            <p className="text-center">No chatbots created yet</p>
          ) : (
            <PerfectScrollbar>
              {chatbots.map((data, index) => {
                return (
                  <div
                    key={`chatbot-${index}`}
                    className="bg-light px-4 py-3 mb-3 rounded d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p className="mb-0 text-secondary">{data.name}</p>
                      <small>{data.project.name}</small>
                    </div>
                    <Tooltip title="Copies this chatbot to the selected account">
                      <Button
                        variant="primary tp-btn btn-xxs"
                        onClick={() => copyChatbot(data)}
                      >
                        Copy
                      </Button>
                    </Tooltip>
                  </div>
                );
              })}
            </PerfectScrollbar>
          )}
        </Modal.Body>
      </Modal>
      <CopyChatbotModal
        show={showCopyChatbotModal}
        showHideHandler={setShowCopyChatbotModal}
        accountId={accountId}
        selectedChatbot={selectedChatbot}
      />
    </>
  );
};

export default ViewChatbotsModal;
