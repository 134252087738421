import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import illustration from "../../../images/admin-ill.png";
import RegexUtils from "../../../utils/Regex";
import AuthService from "../../../services/AuthService";
import useAPI from "../../../hooks/useAPI";
import InputErrorMessage from "../../utils/InputErrorMessage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Login = () => {
  let navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const loginAPI = useAPI(AuthService.login, loginHandler);

  async function login(data) {
    const captchaToken = await executeRecaptcha("control_login");

    loginAPI.execute(data, captchaToken);
  }

  function loginHandler(response) {
    // Set access_token
    cookies.set("access_token", response.data.accessToken, { path: "/" });

    // Set account
    cookies.set("account", response.data.defaultAccountId, { path: "/" });

    navigate("/");
  }

  return (
    <div className="row justify-content-center h-100 align-items-center h-80">
      <div className="col col-12 col-lg-6">
        <div className="text-center mb-3">
          <img
            src={illustration}
            alt="illustration"
            className="auth-illustration"
          />
        </div>
      </div>
      <div className="col col-12 col-lg-6">
        <div className="authentication-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <h3 className="text-center mb-4">Administration Login</h3>
                <form onSubmit={handleSubmit(login)}>
                  <div className="form-group">
                    <label className="mb-1" htmlFor="emailInput">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="hello@example.com"
                      id="emailInput"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegexUtils.EMAIL,
                          message: "Email is invalid",
                        },
                      })}
                      aria-describedby="emailHelp"
                      autoComplete="username"
                    />
                    <InputErrorMessage name="email" errors={errors} />
                  </div>
                  <div className="form-group">
                    <label className="mb-1" htmlFor="passwordInput">
                      <strong>Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="passwordInput"
                      autoComplete="current-password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                    <InputErrorMessage name="password" errors={errors} />
                  </div>
                  <div className="form-row d-flex justify-content-end mt-4 mb-2">
                    <div className="form-group mb-0">
                      <Link to="/auth/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!isValid || loginAPI.status === "pending"}
                    >
                      {loginAPI.status === "pending" ? (
                        <>
                          <div
                            className="spinner-border spinner-border-sm text-light mr-2"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </div>
                          LOGIN
                        </>
                      ) : (
                        "LOGIN"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
