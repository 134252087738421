import { useSelector } from "react-redux";
import { selectAuthUser } from "../../state/slices/authUserSlice";

function withState(Component) {
  function ComponentWithReduxState(props) {
    const authUser = useSelector(selectAuthUser);

    return (
      <Component
        {...props}
        reduxState={{
          authUser,
        }}
      />
    );
  }
  return ComponentWithReduxState;
}

export default withState;
