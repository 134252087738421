import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Switch, Typography, Stack } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LinearProgress from "@mui/material/LinearProgress";
import useAPI from "../../../../hooks/useAPI.js";
import AdminService from "../../../../services/AdminService.js";
import StringUtils from "../../../../utils/String.js";
import SubscriptionUtils from "../../../../utils/Subscription.js";

function PlansModal({ subscriptionId, show, showHideHandler }) {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isAdvancedModelSelected, setisAdvancedModelSelected] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedPgPriceId, setSelectedPgPriceId] = useState("");

  const getAllSubscriptionsPlansAPI = useAPI(
    AdminService.getAllSubscriptionsPlans,
    getSubscriptionPlansHandler,
    false,
    true
  );

  const modifySubscriptionAPI = useAPI(
    AdminService.modifySubscription,
    handleModalClose
  );

  function modifySubscription() {
    modifySubscriptionAPI.execute(subscriptionId, {
      pgPriceId: selectedPgPriceId,
    });
  }

  function handleModelChange() {
    setisAdvancedModelSelected(!isAdvancedModelSelected);

    unsetSelected();
  }

  function unsetSelected() {
    setSelectedPlanId("");
    setSelectedPgPriceId("");
  }

  function handleModalClose() {
    unsetSelected();
    showHideHandler(false);
  }

  /* API success handlers */

  function getSubscriptionPlansHandler(response) {
    setSubscriptionPlans(response.data);
  }

  return (
    <Modal className="modal fade" size="xl" show={show} scrollable>
      <Modal.Header>
        <Modal.Title>Subscription Plans</Modal.Title>
        <Button variant="" className="close" onClick={handleModalClose}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {getAllSubscriptionsPlansAPI.status === "pending" ? (
          <LinearProgress color="inherit" />
        ) : (
          <div className="row">
            {SubscriptionUtils.getPlansBasedOnGPTModel(
              subscriptionPlans,
              isAdvancedModelSelected
            ).map((plan, index) => {
              if (plan.pricing.length === 0) return null; // skip showing the freemium plan
              return (
                <SubscriptionCard
                  key={index}
                  data={plan}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                  setSelectedPgPriceId={setSelectedPgPriceId}
                />
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {/* <Stack direction="row" spacing={1} alignItems="center">
          <Typography>GPT-4o mini</Typography>
          <Switch
            checked={isAdvancedModelSelected}
            onChange={handleModelChange}
          />
          <Typography>GPT-4o</Typography>
        </Stack> */}
        <div>
          <Button
            className="mr-2"
            onClick={handleModalClose}
            variant="danger light"
          >
            Cancel
          </Button>
          {modifySubscriptionAPI.status === "pending" ? (
            <button type="button" className="btn btn-success" disabled>
              <div
                className="spinner-border spinner-border-sm text-light mr-2"
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>
              Modify
            </button>
          ) : selectedPlanId !== "" && selectedPgPriceId !== "" ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={modifySubscription}
            >
              Modify
            </button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function SubscriptionCard({
  data,
  selectedPlanId,
  setSelectedPlanId,
  setSelectedPgPriceId,
}) {
  // skip showing enterprise plans
  if (data.isEnterprise) return null;

  return (
    <div className="col col-12 col-lg-6">
      <div className="card bg-light">
        <div className="card-header border-0 pb-0">
          <h2 className="card-title">{data.name}</h2>
          {selectedPlanId === data._id ? (
            <button
              type="button"
              className="btn btn-xs btn-primary"
              onClick={() => {
                setSelectedPlanId("");
                setSelectedPgPriceId("");
              }}
            >
              Deselect
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-xs btn-outline-primary"
              onClick={() => setSelectedPlanId(data._id)}
            >
              Select
            </button>
          )}
        </div>
        <div className="card-body py-0">
          <small>{data.description}</small>
          <ul className="list-group list-group-flush mt-2">
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>
                Limit of {data.usageLimits.chatbot.chatbots.count} Chatbots
              </span>
              <span className="mb-0">
                <CheckRoundedIcon color="success" />
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>
                Upto{" "}
                <strong>
                  {formatNumber(data.usageLimits.chatbot.queries.count)}
                </strong>{" "}
                Chatbot queries/month
              </span>
              <span className="mb-0">
                <CheckRoundedIcon color="success" />
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>
                Upto{" "}
                <strong>
                  {formatNumber(
                    data.usageLimits.chatbot.knowledgeBaseCharacters.count
                  )}
                </strong>{" "}
                Knowledge Base Characters
              </span>
              <span className="mb-0">
                <CheckRoundedIcon color="success" />
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Media-rich Conversations</span>
              <span className="mb-0">
                {data.services.includes("Media_Rich_Conversation") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Buy Extra Queries</span>
              <span className="mb-0">
                {data.services.includes("Extra_Queries") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Slack Integration</span>
              <span className="mb-0">
                {data.services.includes("Slack_Integration") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Jira Integration</span>
              <span className="mb-0">
                {data.services.includes("Jira_Integration") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Confluence Integration</span>
              <span className="mb-0">
                {data.services.includes("Confluence_Integration") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Discord Integration</span>
              <span className="mb-0">
                {data.services.includes("Discord_Integration") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Predefined Templates</span>
              <span className="mb-0">
                {data.services.includes("Predefined_Templates") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Assistant Behaviours</span>
              <span className="mb-0">
                {data.services.includes("Assistant_Behaviours") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Priority Support</span>
              <span className="mb-0">
                {data.services.includes("Priority_Support") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
            <li className="list-group-item d-flex px-0 py-1 justify-content-between">
              <span>Onboarding Meeting</span>
              <span className="mb-0">
                {data.services.includes("Onboarding_Meeting") ? (
                  <CheckRoundedIcon color="success" />
                ) : (
                  <CloseRoundedIcon color="error" />
                )}
              </span>
            </li>
          </ul>
        </div>
        <div className="card-footer pt-0 pb-0 text-center">
          <div className="row">
            {data.pricing.map((price, index) => {
              return (
                <div
                  className={`col-6 py-3 ${index != 1 ? "border-right" : ""}`}
                  key={`price-${index}`}
                >
                  {selectedPlanId === data._id ? (
                    <input
                      className="form-check-input subscription-plan-pricing-radio"
                      type="radio"
                      id="subscriptionPeriodInput"
                      name="subscriptionPeriod"
                      value={price.pgPriceId}
                      onClick={() => setSelectedPgPriceId(price.pgPriceId)}
                    />
                  ) : null}
                  <h3
                    className={`mb-0 ${
                      index == 0
                        ? "text-info"
                        : index == 1
                        ? "text-primary"
                        : "text-secondary"
                    }`}
                  >
                    {price.currency === "INR" ? "₹" : "$"}
                    {price.pricePerMonth}
                  </h3>
                  <span>
                    <small> per month</small>
                    <br></br>
                  </span>
                  <span>
                    Billed{" "}
                    {StringUtils.formatPeriodicityText(price.periodicity)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function formatNumber(number) {
  if (number === 10000) {
    return "10K";
  } else if (number === 100000) {
    return "100K";
  } else if (number === 1000000) {
    return "1M";
  } else if (number === -1) {
    return <AllInclusiveIcon />;
  } else {
    return number;
  }
}

export default PlansModal;
