import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Authenticator from "../../services/Authenticator.js";
import PageCenterCircleProgress from "../utils/PageCenterCircleProgress.js";

function RequireAuth({ children }) {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    Authenticator.isAuthenticated().then((isAuth) => {
      setIsAuth(isAuth);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <PageCenterCircleProgress />
  ) : isAuth ? (
    children
  ) : (
    <Navigate to="/auth" replace />
  );
}
export default RequireAuth;
