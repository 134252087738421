import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";

import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";

const AddAnnModal = ({ show, showHideHandler }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const typeOptions = [
    { value: "Information", label: "Information" },
    { value: "Warning", label: "Warning" },
    { value: "Critical", label: "Critical" },
  ];

  const addAnnouncementAPI = useAPI(
    AdminService.createAnnouncement,
    addAnnouncementHandler,
    true
  );

  function addAnnouncement(formData) {
    addAnnouncementAPI.execute({ ...formData, isActive: true });
  }

  /* API success handlers */

  function addAnnouncementHandler(response) {
    showHideHandler(false);
    reset();
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Annnouncment</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(addAnnouncement)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="type">
                  Announcement Type
                </label>
                <select
                  className="form-control"
                  name="type"
                  id="type"
                  {...register("type", {
                    required: "Announcement type is required",
                  })}
                >
                  {typeOptions.map((typeOption, index) => (
                    <option key={index} value={typeOption.value}>
                      {typeOption.label}
                    </option>
                  ))}
                </select>
                <InputErrorMessage name="type" errors={errors} />

                <label className="text-black font-w500" htmlFor="message">
                  Announcement Message
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="message"
                  id="message"
                  {...register("message", {
                    required: "Announcement message is required",
                    maxLength: {
                      value: 300,
                      message:
                        "Announcement message should not exceed 300 characters",
                    },
                  })}
                  max={300}
                />
                <InputErrorMessage name="message" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || addAnnouncementAPI.status === "pending"}
                >
                  {addAnnouncementAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      ADD
                    </>
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAnnModal;
