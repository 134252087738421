import React, { useEffect, useState } from "react";
import DataTable from "../../../../utils/DataTable/index.js";
import { COLUMNS } from "./Columns.js";
import { useSearchParams } from "react-router-dom";
import useAPI from "../../../../../hooks/useAPI.js";
import AdminService from "../../../../../services/AdminService.js";
import ConfirmActionModal from "../../../../utils/Modals/ConfirmActionModal.js";

const UserList = () => {
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);

  const [showBlockUserModal, setShowBlockUserModal] = useState(false);

  const [blockUserModalData, setBlockUserModalData] = useState("");

  const [showUnblockUserModal, setShowUnblockUserModal] = useState(false);

  const [unblockUserModalData, setUnblockUserModalData] = useState("");

  const [tableState, setTableState] = useState({
    totalPages: 0,
    page: 1,
    pageSize: 10,
    hasPrevPage: false,
    hasNextPage: false,
  });

  const getUsersAPI = useAPI(AdminService.getUsers, getUsersHandler);
  const getUsersByUserIdAPI = useAPI(
    AdminService.getUsersByUserId,
    getUsersHandler
  );
  const getUsersBySearchAPI = useAPI(
    AdminService.getUsersBySearch,
    getUsersHandler
  );

  const blockUserAPI = useAPI(AdminService.blockUser, blockUserHandler);

  const unblockUserAPI = useAPI(AdminService.unblockUser, unblockUserHandler);

  function getUsers() {
    if (searchParams.has("userId")) {
      getUsersByUserIdAPI.execute(searchParams.get("userId"));
    } else if (searchParams.has("search")) {
      getUsersBySearchAPI.execute(searchParams.get("search"));
    } else {
      getUsersAPI.execute(tableState.page, tableState.pageSize);
    }
  }

  function blockUser(id) {
    blockUserAPI.execute(id);
  }

  function unblockUser(id) {
    unblockUserAPI.execute(id);
  }

  function handleBlock(id) {
    setShowBlockUserModal(true);
    setBlockUserModalData(id);
  }

  function handleUnblock(id) {
    setShowUnblockUserModal(true);
    setUnblockUserModalData(id);
  }

  useEffect(() => {
    getUsers();
  }, [searchParams, tableState.page, tableState.pageSize]);

  /* API success handlers */

  function getUsersHandler(response) {
    let { docs, totalPages, page, limit, hasPrevPage, hasNextPage } =
      response.data;

    setTableState({
      totalPages,
      page: page ? page : 1,
      pageSize: limit,
      hasPrevPage,
      hasNextPage,
    });

    setUsers(docs);
  }

  function blockUserHandler(response) {
    setShowBlockUserModal(false);
    getUsers();
  }

  function unblockUserHandler(response) {
    setShowUnblockUserModal(false);
    getUsers();
  }

  return (
    <>
      <DataTable
        columns={COLUMNS({ handleBlock, handleUnblock })}
        records={users}
        searchable
        tableState={tableState}
        setTableState={setTableState}
        loading={
          getUsersAPI.status === "pending" ||
          getUsersByUserIdAPI.status === "pending" ||
          getUsersBySearchAPI.status === "pending"
        }
      />
      <ConfirmActionModal
        title="Confirm blocking this user?"
        description="User will no longer be able to access their account upon blocking."
        show={showBlockUserModal}
        confirmActionHandler={() => {
          blockUser(blockUserModalData);
        }}
        isActionPending={blockUserAPI.status === "pending"}
        showHideHandler={setShowBlockUserModal}
      />
      <ConfirmActionModal
        title="Confirm unblocking this account?"
        description="User will be able to access their account upon unblocking."
        show={showUnblockUserModal}
        confirmActionHandler={() => {
          unblockUser(unblockUserModalData);
        }}
        isActionPending={unblockUserAPI.status === "pending"}
        showHideHandler={setShowUnblockUserModal}
      />
    </>
  );
};

export default UserList;
