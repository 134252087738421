import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  selectAuthUser,
  updateAuthUser,
} from "../../state/slices/authUserSlice";

import useAPI from "../../hooks/useAPI";
import UserService from "../../services/UserService";

/* Initializes the state values of user necessary for the functioning of components */
function AppState({ children }) {
  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);

  const getUserAPI = useAPI(UserService.getUser, getUserHandler);

  useEffect(() => {
    if (authUser.fullName === "") {
      getUserAPI.execute();
    }
  }, []);

  /* API success handlers */

  function getUserHandler(response) {
    dispatch(updateAuthUser(response.data));
  }

  return children;
}

export default AppState;
