import React, { useState, useEffect } from "react";
import { Table, Button, Dropdown } from "react-bootstrap";
import { useParams } from "react-router";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import SyncProblemRoundedIcon from "@mui/icons-material/SyncProblemRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import ConfirmActionModal from "../../../../utils/Modals/ConfirmActionModal";
import ResourceTypePill from "./ResourceTypePill";
import useAPI from "../../../../../hooks/useAPI";
import TemplateService from "../../../../../services/TemplateService";
import AddKBModal from "./AddKBModal";
import EditKBModal from "./EditKBModal";
import QaContentModal from "./QaContentModal";
import ExtractedContentModal from "./ExtractedContentModal";
import MediaContentModal from "./MediaContentModal";
import { ReactComponent as KebabIcon } from "../../../../../images/kebab.svg";
import SSEClient from "../../../../../sse/ServerSideEvent";

const KnowledgeBase = () => {
  const sseClient = new SSEClient();

  const [showAddKnowledgeBaseModal, setShowAddKnowledgeBaseModal] =
    useState(false);

  const [showEditKnowledgeBaseModal, setShowEditKnowledgeBaseModal] =
    useState(false);
  const [editKnowledgeBaseModalData, setEditKnowledgeBaseModalData] =
    useState("");

  const [showExtractedContentModal, setShowExtractedContentModal] =
    useState(false);

  const [showQaContentModal, setShowQaContentModal] = useState(false);

  const [showMediaContentModal, setShowMediaContentModal] = useState(false);

  const [kbContentModalData, setKbContentModalData] = useState({});

  const [showDeleteKnowledgeBaseModal, setShowDeleteKnowledgeBaseModal] =
    useState(false);
  const [deleteKnowledgeBaseModalData, setDeleteKnowledgeBaseModalData] =
    useState("");

  const { chatbotId } = useParams();

  const [knowledgeBases, setKnowledgeBases] = useState([]);

  const getAllKnowledgeBaseAPI = useAPI(
    TemplateService.getAllKnowledgeBase,
    getAllKnowledgeBaseHandler
  );

  const deleteKnowledgeBaseAPI = useAPI(
    TemplateService.deleteKnowledgeBase,
    deleteKnowledgeBaseHandler
  );

  function getAllKnowledgeBase() {
    getAllKnowledgeBaseAPI.execute(chatbotId);
  }

  function deleteKnowledgeBase(knowledgeBaseId) {
    deleteKnowledgeBaseAPI.execute(chatbotId, knowledgeBaseId);
  }

  const listenToKbStatus = () => {
    sseClient.connect(
      `${process.env.REACT_APP_API_BASE_URL}/control/templates/${chatbotId}/knowledge-base/listen-status`
    );

    sseClient.onMessage((data) => {
      setKnowledgeBases((prevState) =>
        prevState.map((kb) =>
          kb._id === data.knowledgeBaseId ? { ...kb, status: data.status } : kb
        )
      );
    });

    sseClient.onError((event) => {
      sseClient.disconnect();
    });
  };

  useEffect(() => {
    getAllKnowledgeBase();
    listenToKbStatus();

    return () => {
      sseClient.disconnect();
    };
  }, []);

  /* API success handlers */

  function getAllKnowledgeBaseHandler(response) {
    setKnowledgeBases(response.data);
  }

  function deleteKnowledgeBaseHandler(response) {
    setShowDeleteKnowledgeBaseModal(false);
    getAllKnowledgeBase();
  }

  return (
    <>
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        {getAllKnowledgeBaseAPI.status === "pending" ? (
          <Box
            height={300}
            className="d-flex justify-content-center align-items-center text-center"
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <strong>NAME</strong>
                </th>
                <th>
                  <strong>DESCRIPTION</strong>
                </th>
                <th>
                  <strong>RESOURCE TYPE</strong>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {knowledgeBases.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{getStatusIcon(row.status)}</td>
                    <td>{row.name}</td>
                    <td className="text-truncate" style={{ maxWidth: "500px" }}>
                      <Tooltip
                        id={`tooltip-${row._id}`}
                        title={row.description}
                        placement="left"
                      >
                        <span>{row.description}</span>
                      </Tooltip>
                    </td>
                    <td>
                      <ResourceTypePill
                        resourceType={row.resourceType}
                        resource={row.resource}
                      />
                    </td>
                    <td>
                      <Button
                        variant="outline-primary btn-sm"
                        onClick={() => {
                          setKbContentModalData({
                            chatbotId,
                            knowledgeBase: row,
                          });
                          if (
                            row.resourceType === "document" ||
                            row.resourceType === "webpage"
                          ) {
                            setShowExtractedContentModal(true);
                          } else if (row.resourceType === "qa") {
                            setShowQaContentModal(true);
                          } else if (row.resourceType === "media") {
                            setShowMediaContentModal(true);
                          } else if (row.resourceType === "confluence-page") {
                            window.open(row.resource.pageLink, "_blank");
                          }
                        }}
                      >
                        See Content
                      </Button>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btn sharp btn-primary tp-btn i-false"
                          data-toggle="dropdown"
                        >
                          <KebabIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setEditKnowledgeBaseModalData({
                                chatbotId,
                                knowledgeBaseId: row._id,
                                name: row.name,
                                description: row.description,
                              });
                              setShowEditKnowledgeBaseModal(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setDeleteKnowledgeBaseModalData(row._id);
                              setShowDeleteKnowledgeBaseModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
      <div className="text-right mt-3">
        <Button
          className="primary rounded"
          onClick={() => {
            setShowAddKnowledgeBaseModal(true);
          }}
        >
          Add Knowledge Base
        </Button>
      </div>
      <AddKBModal
        show={showAddKnowledgeBaseModal}
        showHideHandler={setShowAddKnowledgeBaseModal}
        data={{
          chatbotId,
        }}
        getAllKnowledgeBase={getAllKnowledgeBase}
      />
      <EditKBModal
        show={showEditKnowledgeBaseModal}
        showHideHandler={setShowEditKnowledgeBaseModal}
        data={editKnowledgeBaseModalData}
        getAllKnowledgeBase={getAllKnowledgeBase}
      />
      <ExtractedContentModal
        show={showExtractedContentModal}
        showHideHandler={setShowExtractedContentModal}
        data={kbContentModalData}
      />
      <QaContentModal
        show={showQaContentModal}
        showHideHandler={setShowQaContentModal}
        data={kbContentModalData}
      />
      <MediaContentModal
        show={showMediaContentModal}
        showHideHandler={setShowMediaContentModal}
        data={kbContentModalData}
      />
      <ConfirmActionModal
        title="Confirm knowledge base delete?"
        description="The chatbot will no longer be able to answer queries belonging to this knowledge base content."
        show={showDeleteKnowledgeBaseModal}
        confirmActionHandler={() => {
          deleteKnowledgeBase(deleteKnowledgeBaseModalData);
        }}
        isActionPending={deleteKnowledgeBaseAPI.status === "pending"}
        showHideHandler={setShowDeleteKnowledgeBaseModal}
      />
    </>
  );
};

function getStatusIcon(status) {
  if (status === "latent") {
    return (
      <Tooltip title="Content not extracted">
        <UpdateRoundedIcon color="light" />
      </Tooltip>
    );
  }
  if (status === "extraction_pending") {
    return (
      <Tooltip title="Content is being extracted">
        <DownloadingRoundedIcon color="warning" />
      </Tooltip>
    );
  }
  if (status === "extraction_completed") {
    return (
      <Tooltip title="Content extraction is complete">
        <SyncRoundedIcon color="primary" />
      </Tooltip>
    );
  }
  if (status.includes("failed")) {
    return (
      <Tooltip title="Resource failed to sync with chatbot">
        <SyncProblemRoundedIcon color="error" />
      </Tooltip>
    );
  }
  return null;
}

export default KnowledgeBase;
