import React, { useState } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";

const KokiNav = ({ onClick: ClickToAddEvent }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <>
      <NavHeader />
      <SideBar />
      <Header
        onProfile={() => onClick("profile")}
        toggle={toggle}
        onClick={() => ClickToAddEvent()}
      />
    </>
  );
};

export default KokiNav;
