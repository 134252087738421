import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";
import RegexUtils from "../../../../utils/Regex";

const ChangeOwnerModal = ({ show, showHideHandler, accountId }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const changeAccountOwnerAPI = useAPI(
    AdminService.transferAccountOwnership,
    changeAccountOwnerHandler
  );

  function changeAccountOwner(formData) {
    changeAccountOwnerAPI.execute(accountId, formData);
  }

  /* API success handlers */

  function changeAccountOwnerHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Account Ownership Change</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(changeAccountOwner)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="emailInput">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="emailInput"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: RegexUtils.EMAIL,
                      message: "Email is invalid",
                    },
                  })}
                />
                <InputErrorMessage name="email" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !isValid || changeAccountOwnerAPI.status === "pending"
                  }
                >
                  {changeAccountOwnerAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      CHANGE
                    </>
                  ) : (
                    "CHANGE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeOwnerModal;
