import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserService from "../../../services/UserService";
import InputErrorMessage from "../../utils/InputErrorMessage";
import RegexUtils from "../../../utils/Regex";
import useAPI from "../../../hooks/useAPI";
import { useDispatch } from "react-redux";
import { updateAuthUser } from "../../../state/slices/authUserSlice";
import withState from "../../utils/withState";

const AppProfile = ({ reduxState }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const updateUserAPI = useAPI(UserService.updateUser, updateUserHandler);

  const dispatch = useDispatch();

  useEffect(() => {
    // Set form data from the authenticated user state objectz
    reset(reduxState.authUser);
  }, [reduxState.authUser]);

  function updateUser(data) {
    updateUserAPI.execute(data);
  }

  /* API success handlers */

  function updateUserHandler(response) {
    dispatch(updateAuthUser(response.data));
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            Profile
            <div className="text-dark text-nowrap mt-1 fs-16">
              Personal Account
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <form onSubmit={handleSubmit(updateUser)}>
            <div className="form-group">
              <label className="text-black font-w500" htmlFor="fullNameInput">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                id="fullNameInput"
                {...register("fullName", {
                  required: "Full Name is required",
                  pattern: {
                    value: RegexUtils.NAME,
                    message: "Full Name is invalid",
                  },
                  minLength: {
                    value: 1,
                    message: "Full Name should have at least 1 character",
                  },
                  maxLength: {
                    value: 30,
                    message: "Full Name should have at most 30 characters",
                  },
                })}
              />
              <InputErrorMessage name="fullName" errors={errors} />
            </div>
            <div className="form-group">
              <label className="text-black font-w500" htmlFor="emailInput">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                name="email"
                id="emailInput"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: RegexUtils.EMAIL,
                    message: "Email is invalid",
                  },
                })}
              />
              <InputErrorMessage name="email" errors={errors} />
            </div>
            <div className="form-group">
              <label className="text-black font-w500" htmlFor="phoneInput">
                Phone{" "}
                <Tooltip title="Enter phone number along with the country code. Ex: +1 123 123 1234">
                  <InfoOutlinedIcon fontSize="xsmall" color="disabled" />
                </Tooltip>
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                id="phoneInput"
                {...register("phone", {
                  required: "Phone is required",
                  pattern: {
                    value: RegexUtils.PHONE,
                    message: "Phone is invalid",
                  },
                })}
              />
              <InputErrorMessage name="phone" errors={errors} />
            </div>
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || updateUserAPI.status === "pending"}
              >
                {updateUserAPI.status === "pending" ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm text-light mr-2"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                    SAVE
                  </>
                ) : (
                  "SAVE"
                )}
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};

export default withState(AppProfile);
