import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

import * as Sentry from "@sentry/react";
import sentryConfig from "./config/sentry.config";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

Sentry.init(sentryConfig);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
