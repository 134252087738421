function validateFileSize(files, maxSizeAllowed) {
  let totalMBytes = 0;

  // calculate the cumulative size of the uploaded documents
  files.forEach((file) => {
    const bytes = file.size;
    const mbytes = bytes / 1024 / 1024;
    totalMBytes += mbytes;
  });

  return totalMBytes < maxSizeAllowed;
}

const FileUtils = {
  validateFileSize,
};

export default FileUtils;
