import { useState } from "react";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const ScannedWebPagesModal = ({
  show,
  showHideHandler,
  scannedWebPages,
  setScannedWebPages,
  handleSubmit,
  isSubmitting,
}) => {
  // State
  const [allSelected, setAllSelected] = useState(false);

  // Handlers
  const handleSelectUrl = (index) => {
    setScannedWebPages((prevState) =>
      prevState.map((data, idx) =>
        idx === index ? { ...data, checked: !data.checked } : data
      )
    );

    setAllSelected(null);
  };

  const handleSelectAll = (checked) => {
    setScannedWebPages((prevState) =>
      prevState.map((data) => ({ ...data, checked }))
    );
    setAllSelected(checked);
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={show}
        onHide={() => {
          showHideHandler(false);
        }}
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose pages to scan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-column h-100">
            <PerfectScrollbar className="flex-grow-1 dlab-scroll p-3">
              {scannedWebPages ? (
                <div>
                  {scannedWebPages.map((data, index) => {
                    return (
                      <div
                        key={`url-${index}`}
                        className="bg-light px-4 py-3 mb-3 rounded d-flex justify-content-between"
                      >
                        <div className="pr-3">
                          <p className="fs-14 mb-1 text-secondary">
                            {data.title}
                          </p>
                          <a
                            href={data.url}
                            target="_blank"
                            className="fs-14 text-break"
                            rel="noreferrer"
                          >
                            {data.url}
                          </a>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => handleSelectUrl(index)}
                            checked={data.checked}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </PerfectScrollbar>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer d-flex justify-content-between align-items-center">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="selectAllInput"
              checked={allSelected === true}
              onChange={() => {
                handleSelectAll(!allSelected);
              }}
            />
            <label className="form-check-label" htmlFor="selectAllInput">
              {allSelected ? "Deselect" : "Select"} all
            </label>
          </div>
          {isSubmitting ? (
            <button type="submit" className="btn btn-primary" disabled>
              <div
                className="spinner-border spinner-border-sm text-light mr-2"
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>
              SUBMIT
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScannedWebPagesModal;
