import UserService from "../services/UserService";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Authenticator {
  static logout() {
    this.clearCookies();
    window.location = "/auth";
  }

  static isAuthenticated() {
    return new Promise(async (resolve) => {
      try {
        const accessToken = cookies.get("access_token");
        let isAuth = accessToken !== undefined;

        if (!isAuth) {
          throw new Error("No access token");
        }

        await UserService.getUser();
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  static clearCookies() {
    // Remove access_token
    cookies.remove("access_token", { path: "/" });
    // Remove account
    cookies.remove("account", { path: "/" });
  }
}

export default Authenticator;
