import { Badge } from "react-bootstrap";
import { Tooltip } from "@mui/material";

export const COLUMNS = ({
  handleEditAnnouncement,
  handleDeleteAnnouncement,
}) => {
  return [
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => {
        let variant = "";
        if (value === "Information") variant = "info";
        else if (value === "Critical") variant = "danger";
        else variant = "warning";
        return (
          <Badge variant={variant + " light badge-rounded badge-sm"}>
            {value}
          </Badge>
        );
      },
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: "Active",
      accessor: "isActive",
      Cell: ({ value }) => {
        let variant = "";
        if (value) variant = "success";
        else variant = "danger";
        return (
          <Badge variant={variant + " light badge-rounded badge-sm"}>
            {value ? "Active" : "Inactive"}
          </Badge>
        );
      },
    },
    {
      Header: "Last Update",
      accessor: "lastUpdatedAt",
      Cell: ({ value }) => {
        return value.substring(0, 10);
      },
    },
    {
      id: "action",
      Header: "",
      accessor: "_id",
      Cell: ({ value }) => {
        return (
          <>
            <Tooltip title="Edit Announcement">
              <button
                type="button"
                className="btn btn-outline-primary shadow btn-xs sharp mr-2"
                onClick={() => handleEditAnnouncement(value)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            </Tooltip>
            <Tooltip title="Delete Announcement">
              <button
                type="button"
                className="btn btn-outline-danger shadow btn-xs sharp"
                onClick={() => {
                  handleDeleteAnnouncement(value);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];
};
