import { useState } from "react";

import { Card, Button } from "react-bootstrap";
import AddAnnModal from "./AddAnnModal";
import AnnouncementList from "./List";

const Account = () => {
  const [showAddAnnModal, setShowAddAnnModal] = useState(false);
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <span>Announcement Management</span>
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => {
              setShowAddAnnModal(true);
            }}
          >
            Add Announcement
          </Button>
        </Card.Header>
        <Card.Body>
          <AnnouncementList />
        </Card.Body>
      </Card>
      <AddAnnModal
        show={showAddAnnModal}
        showHideHandler={setShowAddAnnModal}
      />
    </>
  );
};

export default Account;
