import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AccountService from "../../../../services/AccountService";

const AddUserModal = ({ show, showHideHandler, data, userRoles }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const updateAccountUserAPI = useAPI(
    AccountService.updateAccountUser,
    updateAccountUserHandler
  );

  function updateAccountUser(formData) {
    updateAccountUserAPI.execute(data.user._id, { role: formData.role });
  }

  useEffect(() => {
    if (data) {
      let formData = { email: data.user.email, role: data.role._id };
      reset(formData);
    }
  }, [data]);

  /* API success handlers */

  function updateAccountUserHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit User</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(updateAccountUser)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="emailInput">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  name="email"
                  id="emailInput"
                  {...register("email")}
                />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="projectInput">
                  Role
                </label>
                <select
                  className="form-control"
                  id="roleInput"
                  {...register("role", {
                    required: "Role is required",
                  })}
                >
                  {userRoles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                <InputErrorMessage name="role" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !isValid || updateAccountUserAPI.status === "pending"
                  }
                >
                  {updateAccountUserAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      UPDATE
                    </>
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
