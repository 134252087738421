import React from "react";
import { Container } from "react-bootstrap";

const TestBar = () => {
  return (
    <Container
      fluid
      className="bg-warning text-white fixed-top"
      style={{ height: "30px" }}
    >
      <p className="text-center py-1">You are in test mode</p>
    </Container>
  );
};

export default TestBar;
