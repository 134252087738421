import { useState } from "react";
import { Card, Button } from "react-bootstrap";
import SubscriptionList from "./List";
import AddEntSubscModal from "./AddEntSubscModal";

const Subscription = () => {
  const [showAddEntSubscModal, setShowAddEntSubscModal] = useState(false);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <span>Subscription Management</span>
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => {
              setShowAddEntSubscModal(true);
            }}
          >
            Add Enterprise Subscription
          </Button>
        </Card.Header>
        <Card.Body>
          <SubscriptionList />
        </Card.Body>
      </Card>
      <AddEntSubscModal
        show={showAddEntSubscModal}
        showHideHandler={setShowAddEntSubscModal}
      />
    </>
  );
};

export default Subscription;
