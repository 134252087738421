import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Moment from "react-moment";
import StringUtils from "../../../../../utils/String";
import SubscriptionUtils from "../../../../../utils/Subscription";

export const COLUMNS = () => {
  return [
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        return row.index + 1;
      },
      //disableFilters: true,
    },
    {
      Header: "Account",
      accessor: "account",
      Cell: ({ value }) => {
        // return (
        //   <Link
        //     to={`/accounts?accountId=${value._id}`}
        //     style={{ textDecoration: "underline" }}
        //   >
        //     {value.name}
        //   </Link>
        // );

        return (
          <Tooltip title={value._id}>
            <span>{value.name}</span>
          </Tooltip>
        );
      },
    },
    {
      id: "owner",
      Header: "Owner",
      accessor: "account",
      Cell: ({ value }) => {
        return (
          <Link
            to={`/administration/users?userId=${value.owner._id}`}
            style={{ textDecoration: "underline" }}
          >
            {value.owner.fullName}
          </Link>
        );
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }) => {
        let amount = value / 100;
        // Use toFixed(2) to ensure two decimal places
        return <b>${amount.toFixed(2)}</b>;
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        let variant = "";
        if (value === "succeeded") variant = "success";
        else if (value === "canceled") variant = "danger";
        else variant = "warning";

        return (
          <Badge variant={variant + " light badge-rounded badge-sm"}>
            {StringUtils.formatSnakeCase(value)}
          </Badge>
        );
      },
    },
    {
      Header: "Date",
      accessor: "created",
      Cell: ({ value }) => {
        value = value * 1000; // convert epoch seconds to milli seconds
        return <Moment date={value} format="DD MMM YYYY" />;
      },
    },
  ];
};
