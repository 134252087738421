import { useEffect, useState } from "react";
import DataTable from "../../../../utils/DataTable/index.js";
import { COLUMNS } from "./Columns.js";
import useAPI from "../../../../../hooks/useAPI.js";
import AdminService from "../../../../../services/AdminService.js";

const TransactionList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);

  const [tableState, setTableState] = useState({
    totalPages: 0,
    page: 1,
    pageSize: 10,
    hasPrevPage: false,
    hasNextPage: false,
  });

  const getTransactionsAPI = useAPI(
    AdminService.getTransactions,
    getTransactionsHandler
  );

  function getTransactions(getNext) {
    const firstRecordId =
      transactions.length === 0 || tableState.page === 1
        ? ""
        : transactions[0].id;

    const lastRecordId =
      transactions.length === 0 ? "" : transactions[transactions.length - 1].id;

    getTransactionsAPI.execute(
      getNext ? lastRecordId : firstRecordId,
      getNext,
      tableState.pageSize
    );
  }

  useEffect(() => {
    let getNext = tableState.page > currentPage;

    getTransactions(getNext);

    setCurrentPage(tableState.page);
  }, [tableState.page, tableState.pageSize]);

  /* API success handlers */

  function getTransactionsHandler(response) {
    const { data, has_more } = response.data;

    setTableState((prevState) => {
      prevState.hasPrevPage = prevState.page !== 1;
      prevState.hasNextPage = has_more;
      return prevState;
    });

    setTransactions(data);
  }

  return (
    <>
      <DataTable
        columns={COLUMNS()}
        records={transactions}
        tableState={tableState}
        setTableState={setTableState}
        loading={getTransactionsAPI.status === "pending"}
      />
    </>
  );
};

export default TransactionList;
