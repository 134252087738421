import { OverlayTrigger, Popover, Badge } from "react-bootstrap";

const ResourceTypePill = ({ resourceType, resource }) => {
  if (resourceType === "document") {
    return <DocumentPill documents={resource.originalFilenames} />;
  } else if (resourceType === "webpage") {
    return <WebpagePill urls={resource?.urls} />;
  } else if (resourceType === "qa") {
    return <QaPill qas={resource} />;
  } else if (resourceType === "media") {
    return <MediaPill attachments={resource.attachments} />;
  } else if (resourceType === "confluence-page") {
    return <ConfluencePagePill />;
  } else {
    return null;
  }
};

const DocumentPill = ({ documents }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover id="popover-positioned-top">
          <Popover.Title as="h3">Document</Popover.Title>
          <Popover.Content>
            <ul>
              {documents.map((document, index) => (
                <li key={`document-${index}`}>{index + 1 + ". " + document}</li>
              ))}
            </ul>
          </Popover.Content>
        </Popover>
      }
    >
      <Badge as="a" href="#" variant="info badge-rounded">
        Document
      </Badge>
    </OverlayTrigger>
  );
};

const WebpagePill = ({ urls }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      overlay={
        <Popover id="popover-positioned-left">
          <Popover.Title as="h3">Webpage</Popover.Title>
          <Popover.Content>
            <ul>
              {urls.map((url, index) => (
                <li key={`webpage-${index}`}>{index + 1 + ". " + url}</li>
              ))}
            </ul>
          </Popover.Content>
        </Popover>
      }
    >
      <Badge as="a" href="#" variant="secondary badge-rounded">
        Webpage
      </Badge>
    </OverlayTrigger>
  );
};

const QaPill = ({ qas }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover id="popover-positioned-top">
          <Popover.Title as="h3">QA</Popover.Title>
          <Popover.Content>Count: {qas.length}</Popover.Content>
        </Popover>
      }
    >
      <Badge as="a" href="#" variant="success badge-rounded">
        QA
      </Badge>
    </OverlayTrigger>
  );
};

const MediaPill = ({ attachments }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover id="popover-positioned-top" variant="primary">
          <Popover.Title as="h3">Attachments</Popover.Title>
          <Popover.Content>{attachments.length} files</Popover.Content>
        </Popover>
      }
    >
      <Badge as="a" href="#" variant="warning badge-rounded">
        Media
      </Badge>
    </OverlayTrigger>
  );
};

const ConfluencePagePill = () => {
  return (
    <Badge as="a" href="#" variant="primary badge-rounded">
      Confluence Page
    </Badge>
  );
};

export default ResourceTypePill;
