import { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, LinearProgress } from "@mui/material";
import { Tab, Nav, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AccountService from "../../../../services/AccountService";
import AuthService from "../../../../services/AuthService";
import RegexUtils from "../../../../utils/Regex";
import AppConstants from "../../../../constants/App";
import ConfirmActionModal from "../../../utils/Modals/ConfirmActionModal";

const Settings = () => {
  const [accountData, setAccountData] = useState({
    name: "",
  });
  const {
    register: registerBasic,
    formState: { errors: errorsBasic },
    handleSubmit: handleSubmitBasic,
    setValue: setValueBasic,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const [showConvertOrgAccModal, setShowConvertOrgAccModal] = useState(false);

  const getUserAccountAPI = useAPI(
    AccountService.getUserAccount,
    getUserAccountHandler,
    false,
    true
  );

  const updateAccountNameAPI = useAPI(
    AccountService.updateAccountName,
    updateAccountNameHandler
  );

  const convertToOrgAccAPI = useAPI(
    AccountService.convertToOrgAcc,
    convertToOrgAccHandler
  );
  const resetPasswordAPI = useAPI(
    AuthService.resetPassword,
    resetPasswordHandler,
    true
  );

  function updateAccountName(data) {
    updateAccountNameAPI.execute({ name: data.accountName });
  }

  function convertToOrgAcc() {
    convertToOrgAccAPI.execute();
  }

  function resetPassword(data) {
    resetPasswordAPI.execute(data);
  }

  /* API Success Handlers */

  function getUserAccountHandler(response) {
    setAccountData(response.data);
    // set the account name input value inside form
    setValueBasic("accountName", response.data.name);
  }

  function convertToOrgAccHandler(response) {
    setAccountData(response.data);
    setShowConvertOrgAccModal(false);
  }

  function updateAccountNameHandler(response) {
    window.location.reload();
  }

  function resetPasswordHandler(response) {
    reset();
  }

  return (
    <>
      <div className="page-titles mb-1">
        <div className="d-flex justify-content-between flex-wrap">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link>Account</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={"#"}>Settings</Link>
            </li>
          </ol>
        </div>
      </div>
      <div className="card">
        <div className="mx-4 my-5">
          <Tab.Container defaultActiveKey="basic">
            <div className="row">
              <div className="col-xl-3 col-xxl-4 col-lg-4 col-sm-4">
                <Nav as="ul" className="flex-column nav-pills mb-3">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="basic">Basic</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="restrictions">Reset Password</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-xl-9 col-xxl-8 col-lg-8 col-sm-8">
                <Tab.Content className="ml-2">
                  <Tab.Pane eventKey="basic">
                    {getUserAccountAPI.status === "pending" ? (
                      <LinearProgress color="inherit" />
                    ) : accountData?.type === "Personal" ? (
                      <>
                        <Typography variant="h5" mb={2}>
                          <b>Personal</b> Account
                        </Typography>
                        <Button
                          variant="outline-warning btn-square btn-sm"
                          onClick={() => {
                            setShowConvertOrgAccModal(true);
                          }}
                        >
                          Convert to Organization account
                        </Button>
                      </>
                    ) : (
                      <form onSubmit={handleSubmitBasic(updateAccountName)}>
                        <div className="form-group">
                          <label
                            className="text-black font-w500"
                            htmlFor="accountNameInput"
                          >
                            Organization Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="accountName"
                            id="accountNameInput"
                            {...registerBasic("accountName", {
                              required: "Account name is required",
                              pattern: {
                                value: RegexUtils.NAME,
                                message: "Account name is invalid",
                              },
                            })}
                          />
                          <InputErrorMessage
                            name="accountName"
                            errors={errorsBasic}
                          />
                        </div>
                        <div className="form-group mt-2">
                          {updateAccountNameAPI.status === "pending" ? (
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                              disabled
                            >
                              <div
                                className="spinner-border spinner-border-sm text-light mr-2"
                                role="status"
                              >
                                <span className="visually-hidden"></span>
                              </div>
                              SAVE
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </form>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="restrictions">
                    <form onSubmit={handleSubmit(resetPassword)}>
                      <div className="form-group">
                        <label
                          className="text-black font-w500"
                          htmlFor="currentPasswordInput"
                        >
                          Current Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="currentPassword"
                          id="currentPasswordInput"
                          autoComplete="current-password"
                          {...register("currentPassword", {
                            required: "Current Password is required",
                            pattern: {
                              value: RegexUtils.PASSWORD,
                              message: "Current Password is invalid",
                            },
                          })}
                        />
                        <InputErrorMessage
                          name="currentPassword"
                          errors={errors}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          className="text-black font-w500"
                          htmlFor="newPasswordInput"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="newPassword"
                          id="newPasswordInput"
                          autoComplete="new-password"
                          {...register("newPassword", {
                            required: "New Password is required",
                            pattern: {
                              value: RegexUtils.PASSWORD,
                              message: "New Password is invalid",
                            },
                          })}
                        />
                        <InputErrorMessage name="newPassword" errors={errors} />
                      </div>
                      <div className="form-group">
                        <label
                          className="text-black font-w500"
                          htmlFor="confirmPasswordInput"
                        >
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          id="confirmPasswordInput"
                          autoComplete="new-password"
                          {...register("confirmPassword", {
                            required: "Confirm Password is required",
                            pattern: {
                              value: RegexUtils.PASSWORD,
                              message: "Confirm Password is invalid",
                            },
                          })}
                        />
                        <InputErrorMessage
                          name="confirmPassword"
                          errors={errors}
                        />
                      </div>
                      <small>{AppConstants.passwordConstraints}</small>
                      <div className="form-group mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          disabled={
                            !isValid || resetPasswordAPI.status === "pending"
                          }
                        >
                          {resetPasswordAPI.status === "pending" ? (
                            <>
                              <div
                                className="spinner-border spinner-border-sm text-light mr-2"
                                role="status"
                              >
                                <span className="visually-hidden"></span>
                              </div>
                              RESET
                            </>
                          ) : (
                            "RESET"
                          )}
                        </button>
                      </div>
                    </form>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
      <ConfirmActionModal
        title="Confirm converting to Organization?"
        description="This will enable organizational options for account management"
        show={showConvertOrgAccModal}
        confirmActionHandler={() => {
          convertToOrgAcc();
        }}
        isActionPending={convertToOrgAccAPI.status === "pending"}
        showHideHandler={setShowConvertOrgAccModal}
      />
    </>
  );
};

export default Settings;
