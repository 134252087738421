import { Card, Button } from "react-bootstrap";
import UserList from "./List";

const User = () => {
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <span>User Management</span>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <UserList />
        </Card.Body>
      </Card>
    </>
  );
};

export default User;
