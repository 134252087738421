import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AdminService from "../../../../services/AdminService";

const AddEntSubscModal = ({ show, showHideHandler }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const addEntSubscAPI = useAPI(
    AdminService.addEnterpriseSubscription,
    addEntSubscHandler,
    true
  );

  function addEntSubsc(formData) {
    addEntSubscAPI.execute({ accountId: formData.account });
  }

  /* API success handlers */

  function addEntSubscHandler(response) {
    showHideHandler(false);
    reset();
    // window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Add Enterprise Subscription to Account
            </h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(addEntSubsc)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="accountInput">
                  Account ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account"
                  id="accountInput"
                  {...register("account", {
                    required: "Account ID is required",
                  })}
                />
                <small className="form-text text-muted">
                  Enter the ID of account to which you want to add an enterprise
                  subscription
                </small>
                <InputErrorMessage name="account" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || addEntSubscAPI.status === "pending"}
                >
                  {addEntSubscAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      ADD
                    </>
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddEntSubscModal;
