import { Card } from "react-bootstrap";
import TransactionList from "./List";

const Transaction = () => {
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <span>Transaction Management</span>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <TransactionList />
        </Card.Body>
      </Card>
    </>
  );
};

export default Transaction;
