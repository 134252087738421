//import React from "react";

import React from "react";

/// React router dom
import { Routes, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import AuthHome from "./pages/AuthHome";
import Home from "./pages/Home";

/// Pages
import Error404 from "./pages/Error404";

/// App
import Login from "./components/Auth/Login";
import ForgotPassword from "./components/Auth/ForgotPassword";

/// App
import Profile from "./components/Profile/Profile";
import Dashboard from "./components/Dashboard/Dashboard";

/// Administration
import Account from "./components/Administration/Account/Account";
import User from "./components/Administration/User/User";
import Subscription from "./components/Administration/Subscription/Subscription";
import Transaction from "./components/Administration/Transaction/Transaction";

/// Management
import Template from "./components/Management/Template";
import TemplateDetail from "./components/Management/Template/TemplateDetail";
import Assistant from "./components/Management/Assistants";
import Announcement from "./components/Management/Announcement/Announcement";

/// Account
import AccountUsers from "./components/Account/Users/Users";
import AccountSettings from "./components/Account/Settings/Settings";

import RequireAuth from "./utils/RequireAuth";
import AppState from "./utils/AppState";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthHome />}>
        <Route index element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth>
            <AppState>
              <Home />
            </AppState>
          </RequireAuth>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="/administration">
          <Route path="accounts" element={<Account />} />
          <Route path="users" element={<User />} />
          <Route path="subscriptions" element={<Subscription />} />
          <Route path="transactions" element={<Transaction />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="/management">
          <Route path="templates" element={<Template />} />
          <Route path="templates/:chatbotId" element={<TemplateDetail />} />
          <Route path="assistants" element={<Assistant />} />
          <Route path="announcements" element={<Announcement />} />
        </Route>
        <Route path="/account">
          <Route path="system-users" element={<AccountUsers />} />
          <Route path="settings" element={<AccountSettings />} />
        </Route>
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRouter;
