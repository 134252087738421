import React, { useState, useEffect } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import useAPI from "../../../../../hooks/useAPI";
import TemplateService from "../../../../../services/TemplateService";
import InputErrorMessage from "../../../../utils/InputErrorMessage";

const QaContentModal = ({ show, showHideHandler, data }) => {
  if (Object.keys(data).length === 0) return null;
  if (data.knowledgeBase.resourceType !== "qa") return null;

  const [isEditing, setIsEditing] = useState(false);
  const [qas, setQas] = useState([]);

  const {
    register,
    unregister,
    formState: { errors, isValid },
    trigger,
    getValues,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    initFormInputData();
  }, [data.knowledgeBase.resource]);

  function initFormInputData() {
    setQas([
      ...data.knowledgeBase.resource.map((_, index) => {
        return index;
      }),
    ]);

    let formData = {};
    data.knowledgeBase.resource.forEach((qa, index) => {
      if (qa) {
        formData[`question-${index}`] = qa.question;
        formData[`answer-${index}`] = qa.answer;
      }
    });

    reset(formData);
  }

  const updateKnowledgeBaseQaAPI = useAPI(
    TemplateService.updateKnowledgeBaseQA,
    updateKnowledgeBaseQaHandler
  );

  async function updateKnowledgeBaseQA() {
    const isFormValid = await trigger();
    if (!isFormValid) {
      return;
    }
    const formData = getValues();
    let qasList = [];
    for (let prop in formData) {
      if (prop.includes("question")) {
        qasList.push({
          question: formData[prop],
          answer: formData["answer-" + prop.split("-")[1]],
        });
      }
    }

    updateKnowledgeBaseQaAPI.execute(data.chatbotId, data.knowledgeBase._id, {
      data: qasList,
    });
  }

  function updateKnowledgeBaseQaHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      className="fade bd-example-modal-lg modal-dialog-scrollable"
      show={show}
      onHide={() => showHideHandler(false)}
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Question & Answers</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => showHideHandler(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        {qas.map((qaIndex) => {
          if (qaIndex === undefined) {
            return null;
          }
          return (
            <div
              key={`kb-qa-${qaIndex}`}
              className="d-flex justify-content-between align-items-start p-1 mb-2 rounded shadow-sm "
            >
              <div className="flex-grow-1 p-3">
                <div className="mb-3">
                  <label
                    className="text-black font-w500"
                    htmlFor={`questionInput-${qaIndex}`}
                  >
                    Question
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name={`question-${qaIndex}`}
                    id={`questionInput-${qaIndex}`}
                    {...register(`question-${qaIndex}`, {
                      required: "question is required",
                    })}
                    disabled={!isEditing}
                  />
                  <InputErrorMessage
                    name={`question-${qaIndex}`}
                    errors={errors}
                  />
                </div>
                <div>
                  <label
                    className="text-black font-w500"
                    htmlFor={`answerInput-${qaIndex}`}
                  >
                    Answer
                  </label>
                  <textarea
                    cols="30"
                    rows="3"
                    className="form-control bg-transparent"
                    name={`answer-${qaIndex}`}
                    id={`answerInput-${qaIndex}`}
                    {...register(`answer-${qaIndex}`, {
                      required: "answer is required",
                    })}
                    disabled={!isEditing}
                  />
                  <InputErrorMessage
                    name={`answer-${qaIndex}`}
                    errors={errors}
                  />
                </div>
              </div>
              {isEditing ? (
                <IconButton
                  aria-label="delete"
                  color="error"
                  size="small"
                  onClick={() => {
                    qas[qaIndex] = undefined;
                    unregister(`question-${qaIndex}`);
                    unregister(`answer-${qaIndex}`);
                    setQas([...qas]);
                  }}
                >
                  <DeleteOutlineRoundedIcon fontSize="inherit" />
                </IconButton>
              ) : null}
            </div>
          );
        })}
        {isEditing ? (
          <p className="mb-2">
            Add question and answer{" "}
            <IconButton
              color="info"
              className="p-0"
              onClick={() => {
                setIsEditing(true);
                setQas([...qas, qas.length]);
              }}
            >
              <AddCircleOutlineRoundedIcon />
            </IconButton>
          </p>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <span>
          {data.knowledgeBase.extractedContent
            ? data.knowledgeBase.extractedContent.length
            : 0}{" "}
          characters
        </span>
        <div>
          {isEditing ? (
            <>
              <Button
                className="mr-2"
                onClick={() => {
                  initFormInputData();
                  setIsEditing(false);
                }}
                variant="danger light"
              >
                Cancel
              </Button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  !isValid || updateKnowledgeBaseQaAPI.status === "pending"
                }
                onClick={
                  isValid && updateKnowledgeBaseQaAPI.status !== "pending"
                    ? updateKnowledgeBaseQA
                    : undefined
                }
              >
                {updateKnowledgeBaseQaAPI.status === "pending" ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm text-light mr-2"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                    Save
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setIsEditing(true)}
            >
              Edit Content
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default QaContentModal;
