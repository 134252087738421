import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import LinearProgress from "@mui/material/LinearProgress";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import TemplateService from "../../../../services/TemplateService";
import AssistantService from "../../../../services/AssistantService";

const Template = () => {
  const [modalShow, setModalShow] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [templates, setTemplates] = useState([]);

  useAPI(AssistantService.getAssistants, getAssistantsHandler, false, true);

  const getTemplatesAPI = useAPI(
    TemplateService.getTemplates,
    getTemplatesHandler,
    false,
    true
  );

  /* API success handlers */

  function getAssistantsHandler(response) {
    setAssistants(response.data);
  }

  function getTemplatesHandler(response) {
    setTemplates(response.data);
  }

  return (
    <>
      <div className="page-titles mb-1">
        <div className="d-flex justify-content-end flex-wrap">
          <Link
            onClick={() => {
              setModalShow(true);
            }}
            className="btn btn-primary rounded"
          >
            New Template
          </Link>
        </div>
      </div>
      <div>
        {getTemplatesAPI.status === "pending" ? (
          <LinearProgress color="inherit" />
        ) : templates.length === 0 ? (
          <div className="my-5">
            <p className="text-center">No templates created</p>
          </div>
        ) : null}

        <div className="row">
          {templates.map((row, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6 col-sm-6">
                <Link to={`${row._id}`}>
                  <div className="card overflow-hidden clickable">
                    <div className="card-body">
                      <div className="text-center">
                        <h3 className="mb-0">{row.name}</h3>
                      </div>
                    </div>
                    {/* <div className="card-footer d-flex justify-content-between text-center">
                      <div>
                        <span className="fs-12">Updated By</span>
                        <span className="d-block fs-16 text-secondary">
                          {row.lastUpdatedBy.fullName}
                        </span>
                      </div>
                      <div>
                        <span className="fs-12">Updated At</span>
                        <span className="d-block fs-16 text-secondary">
                          <Moment format="DD MMM YY, hh:mm A">
                            {row.lastUpdatedAt}
                          </Moment>
                        </span>
                      </div>
                    </div> */}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <CreateTemplateModal
        show={modalShow}
        showHideHandler={setModalShow}
        assistants={assistants}
      />
    </>
  );
};

const CreateTemplateModal = ({ show, showHideHandler, assistants }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const createTemplateAPI = useAPI(
    TemplateService.createTemplate,
    createTemplateHandler
  );

  function createTemplate(formData) {
    createTemplateAPI.execute(formData);
  }

  /* API success handlers */

  function createTemplateHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Template</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(createTemplate)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="nameInput">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="nameInput"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 1,
                      message: "Name should have at least 1 char",
                    },
                    maxLength: {
                      value: 30,
                      message: "Name should have at most 30 chars",
                    },
                  })}
                />
                <InputErrorMessage name="name" errors={errors} />
              </div>
              <div className="form-group">
                <label
                  className="text-black font-w500"
                  htmlFor="assistantInput"
                >
                  Behaviour
                </label>
                <select
                  className="form-control"
                  id="assistantInput"
                  {...register("assistant")}
                >
                  {assistants.map((assistant) => (
                    <option
                      key={`assistant-input-${assistant._id}`}
                      value={assistant._id}
                    >
                      {assistant.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || createTemplateAPI.status === "pending"}
                >
                  {createTemplateAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      CREATE
                    </>
                  ) : (
                    "CREATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Template;
