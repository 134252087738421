import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../../utils/InputErrorMessage";
import useAPI from "../../../../../hooks/useAPI";
import TemplateService from "../../../../../services/TemplateService";

const EditKBModal = ({ show, showHideHandler, data, getAllKnowledgeBase }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const updateKnowledgeBaseAPI = useAPI(
    TemplateService.updateKnowledgeBase,
    updateKnowledgeBaseHandler
  );

  function updateKnowledgeBase(formData) {
    updateKnowledgeBaseAPI.execute(
      data.chatbotId,
      data.knowledgeBaseId,
      formData
    );
  }

  /* API success handlers */

  function updateKnowledgeBaseHandler(response) {
    showHideHandler(false);
    getAllKnowledgeBase();
  }

  return (
    <Modal className="modal fade" id="addOrderModalside" show={show}>
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Knowledge Base</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(updateKnowledgeBase)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="nameInput">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="nameInput"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 1,
                      message: "Name should have at least 1 char",
                    },
                    maxLength: {
                      value: 70,
                      message: "Name should have at most 70 chars",
                    },
                  })}
                />
                <InputErrorMessage name="name" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500">
                  Description <small>(Optional)</small>
                </label>
                <textarea
                  cols="30"
                  rows="2"
                  className="form-control bg-transparent"
                  name="description"
                  id="descriptionInput"
                  {...register("description", {
                    maxLength: {
                      value: 300,
                      message: "Description should have at most 300 chars",
                    },
                  })}
                />
                <InputErrorMessage name="description" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !isValid || updateKnowledgeBaseAPI.status === "pending"
                  }
                >
                  {updateKnowledgeBaseAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      UPDATE
                    </>
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditKBModal;
