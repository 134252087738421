import RegexUtils from "./Regex";

function formatPeriodicityText(periodicity) {
  if (periodicity === "month") {
    return "Monthly";
  } else if (periodicity === "half-year") {
    return "Half Yearly";
  } else if (periodicity === "year") {
    return "Yearly";
  } else if (periodicity === "forever") {
    return "Forever";
  } else {
    return "";
  }
}

function formatKebabCase(string) {
  const words = string.split("-");
  const capitalized = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalized.join(" ");
}

function formatSnakeCase(string) {
  const words = string.split("_");
  const capitalized = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalized.join(" ");
}

function getFileFormatFromUrl(url) {
  const match = url.match(/\.([a-zA-Z0-9]+)(?:\?|$)/);
  if (match && match[1]) {
    return match[1].toLowerCase();
  } else {
    return null;
  }
}

function checkFileFormat(url) {
  const fileFormat = getFileFormatFromUrl(url);

  if (RegexUtils.IMAGE_EXTENSIONS.test(fileFormat)) {
    return "image";
  } else if (RegexUtils.VIDEO_EXTENSIONS.test(fileFormat)) {
    return "video";
  } else {
    return "unknown";
  }
}

const StringUtils = {
  formatPeriodicityText,
  formatKebabCase,
  formatSnakeCase,
  checkFileFormat,
};

export default StringUtils;
