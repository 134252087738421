import React, { useEffect } from "react";

/// Components
import AppRouter from "./jsx/router";

/// Style
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { SnackbarProvider } from "notistack";
import SnackbarConstants from "./constants/Snackbar.js";
import ErrorBoundary from "./jsx/utils/ErrorBoundary";
import store from "./state/store";

const App = () => {
  const body = document.querySelector("body");

  const { width } = useResizeDetector();

  width === undefined || width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : width <= 1299 && width >= 767
    ? body.setAttribute("data-sidebar-style", "mini")
    : body.setAttribute("data-sidebar-style", "overlay");

  // To ignore "ResizeObserver loop limit exceeded" and "ResizeObserver loop completed with undelivered notifications." error that is getting produced during development
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={SnackbarConstants.maxSnack}
          autoHideDuration={SnackbarConstants.autoHideDuration}
          anchorOrigin={SnackbarConstants.anchorOrigin}
        >
          <ErrorBoundary>
            <AppRouter />
          </ErrorBoundary>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
