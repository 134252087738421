import ApiBaseService from "./ApiBaseService";

class TemplateService {
  static getTemplates() {
    return ApiBaseService.request("/control/templates", "GET");
  }

  static createTemplate([data]) {
    return ApiBaseService.request(`/control/templates`, "POST", data);
  }

  static getTemplate([chatbotId]) {
    return ApiBaseService.request(`/control/templates/${chatbotId}`, "GET");
  }

  static updateTemplate([chatbotId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}`,
      "PATCH",
      data
    );
  }

  static deleteTemplate([chatbotId]) {
    return ApiBaseService.request(`/control/templates/${chatbotId}`, "DELETE");
  }

  static getAllKnowledgeBase([chatbotId]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base`,
      "GET"
    );
  }

  static getKnowledgeBase([chatbotId, knowledgeBaseId]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}`,
      "GET"
    );
  }

  static addKnowledgeBaseDocument([chatbotId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/document`,
      "POST",
      formData,
      { headers }
    );
  }

  static addKnowledgeBaseWebPage([chatbotId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/web-page`,
      "POST",
      data
    );
  }

  static scanWebPageNestedUrls([chatbotId, url]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/web-page/scan-nested-urls?url=${url}`,
      "GET"
    );
  }

  static addKnowledgeBaseQA([chatbotId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/qa`,
      "POST",
      data
    );
  }

  static addKnowledgeBaseMedia([chatbotId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/media`,
      "POST",
      formData,
      { headers }
    );
  }

  static updateKnowledgeBase([chatbotId, knowledgeBaseId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}`,
      "PATCH",
      data
    );
  }

  static updateKnowledgeBaseContent([chatbotId, knowledgeBaseId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}/content`,
      "PATCH",
      data
    );
  }

  static updateKnowledgeBaseQA([chatbotId, knowledgeBaseId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}/qa`,
      "PATCH",
      data
    );
  }

  static updateKnowledgeBaseMedia([chatbotId, knowledgeBaseId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}/media`,
      "PATCH",
      formData,
      { headers }
    );
  }

  static deleteKnowledgeBase([chatbotId, knowledgeBaseId]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}`,
      "DELETE"
    );
  }

  static getKnowledgeBaseMediaFile([chatbotId, knowledgeBaseId, fileId]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/${knowledgeBaseId}/media/attachments/${fileId}`,
      "GET"
    );
  }

  static prepareDocumentUpload([chatbotId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/document/prepare-upload`,
      "POST",
      data
    );
  }

  static uploadDocumentToUrl([url, data, onUploadProgress]) {
    const headers = {
      "Content-Type": "application/zip",
    };
    return ApiBaseService.request(
      url,
      "PUT",
      data,
      {
        headers,
        onUploadProgress,
      },
      true
    );
  }

  static processUploadedDocument([chatbotId, data]) {
    return ApiBaseService.request(
      `/control/templates/${chatbotId}/knowledge-base/document/process-upload`,
      "POST",
      data
    );
  }
}

export default TemplateService;
