import { Badge } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import StringUtils from "../../../../../utils/String";
import SubscriptionUtils from "../../../../../utils/Subscription";

export const COLUMNS = ({
  handleModifySubscription,
  handleCancelSubscription,
}) => {
  return [
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        return row.index + 1;
      },
      //disableFilters: true,
    },
    {
      Header: "Account",
      accessor: "account",
      Cell: ({ value }) => {
        // return (
        //   <Link
        //     to={`/accounts?accountId=${value._id}`}
        //     style={{ textDecoration: "underline" }}
        //   >
        //     {value.name}
        //   </Link>
        // );
        return value.name;
      },
    },
    {
      id: "owner",
      Header: "Owner",
      accessor: "account",
      Cell: ({ value }) => {
        return (
          <Link
            to={`/administration/users?userId=${value.owner._id}`}
            style={{ textDecoration: "underline" }}
          >
            {value.owner.fullName}
          </Link>
        );
      },
    },
    {
      id: "subscriptionName",
      Header: "Subscription Plan",
      accessor: "subscriptionPlan",
      Cell: ({ value }) => {
        if (value) {
          return SubscriptionUtils.removeModelNameFromPlanName(value.name);
        }
        return "";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        let variant = "";
        if (value === "active") variant = "success";
        else if (value === "canceled") variant = "danger";
        else variant = "warning";

        return (
          <Badge variant={variant + " light badge-rounded badge-sm"}>
            {StringUtils.formatSnakeCase(value)}
          </Badge>
        );
      },
    },
    {
      Header: "Billed",
      accessor: "periodicity",
      Cell: ({ value }) => {
        return StringUtils.formatPeriodicityText(value);
      },
    },
    {
      Header: "Begin Date",
      accessor: "beginsOn",
      Cell: ({ value }) => {
        return value.substring(0, 10);
      },
    },
    {
      Header: "End Date",
      accessor: "endsOn",
      Cell: ({ value }) => {
        return value?.substring(0, 10);
      },
    },
    {
      id: "action",
      Header: "",
      accessor: "_id",
      Cell: ({ value }) => {
        return (
          <div className="d-flex">
            <Tooltip title="Modify Subscription Plan">
              <button
                type="button"
                className="btn btn-outline-primary shadow btn-xs sharp mr-2"
                onClick={() => handleModifySubscription(value)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            </Tooltip>
            <Tooltip title="Cancel Subscription">
              <button
                type="button"
                className="btn btn-outline-danger shadow btn-xs sharp"
                onClick={() => handleCancelSubscription(value)}
              >
                <i className="fa fa-times"></i>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
};
