const RegexUtils = {
  NAME: /^[a-zA-Z][a-zA-Z ]*$/,
  EMAIL: /^\S+@\S+\.\S+$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{8,}$/,
  OTP: /^$|[0-9]{6}/,
  PHONE:
    /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/, // validates any international number
  NUMERIC: /^(0|[0-9]\d*)(\.\d+)?$/,
  COUPON_CODE: /^[A-Z]{8}$/,
  DIGIT: /\d/, // checks if a string contains a digit
  URL: /^(https?):\/\/[^\s/$.?#].[^\s]*$/, // checks for http, https also
  IMAGE_EXTENSIONS: /^(jpg|jpeg|png|gif|bmp|webp|svg)$/i,
  VIDEO_EXTENSIONS: /^(mp4|avi|mkv|mov|flv|wmv|webm)$/i,
};

export default RegexUtils;
