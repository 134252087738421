import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";

import AdminService from "../../../../services/AdminService";

const CopyChatbotModal = ({
  show,
  showHideHandler,
  accountId,
  selectedChatbot,
}) => {
  if (!show) return; // stops redundant api calls

  const [projects, setProjects] = useState([]);
  const [isValidAccId, setIsValidAccId] = useState(false);

  const {
    register,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const getAccountProjectsAPI = useAPI(
    AdminService.getAccountProjects,
    getProjectsHandler
  );

  const copyChatbotAcrossAccountAPI = useAPI(
    AdminService.copyChatbotAcrossAccount,
    copyChatbotAcrossAccountHandler
  );

  useEffect(() => {
    if (selectedChatbot) {
      setValue("name", selectedChatbot.name + " Copy");
    }
  }, [accountId, selectedChatbot]);

  function getProjects(accId) {
    getAccountProjectsAPI.execute(accId);
  }

  function copyChatbotAcrossAccount(formData) {
    let data = {
      accountId: formData.account,
      projectId: formData.project,
      data: {
        name: formData.name,
      },
    };

    copyChatbotAcrossAccountAPI.execute(accountId, selectedChatbot._id, data);
  }

  function handleAccountInputBlur(event) {
    setIsValidAccId(false);
    setProjects([]);
    getProjects(event.target.value);
  }

  /* API success handlers */

  function getProjectsHandler(response) {
    setIsValidAccId(true);

    setProjects(response.data);

    setValue("project", response.data[0]?._id);
  }

  function copyChatbotAcrossAccountHandler(response) {
    showHideHandler(false);
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Copy Chatbot Across Account</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(copyChatbotAcrossAccount)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="nameInput">
                  Name
                </label>

                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="nameInput"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 1,
                      message: "Name should have at least 1 char",
                    },
                    maxLength: {
                      value: 30,
                      message: "Name should have at most 30 chars",
                    },
                  })}
                />
                <InputErrorMessage name="name" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="accountInput">
                  Account ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account"
                  id="accountInput"
                  {...register("account", {
                    required: "Account ID is required",
                  })}
                  onBlur={handleAccountInputBlur}
                />
                <small className="form-text text-muted">
                  Choose the account in which you want to create chatbot.
                </small>
                <InputErrorMessage name="account" errors={errors} />
              </div>
              {isValidAccId ? (
                <div className="form-group">
                  <label
                    className="text-black font-w500"
                    htmlFor="projectInput"
                  >
                    Project
                  </label>
                  <select
                    className="form-control"
                    id="projectInput"
                    {...register("project", {
                      required: "Project is required",
                    })}
                  >
                    {projects.map((project) => (
                      <option key={project._id} value={project._id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-muted">
                    Choose the project in which you want to create chatbot.
                  </small>
                  <InputErrorMessage name="project" errors={errors} />
                </div>
              ) : getAccountProjectsAPI.status === "pending" ? (
                <CircularProgress color="inherit" size={30} />
              ) : null}

              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !isValid ||
                    copyChatbotAcrossAccountAPI.status === "pending" ||
                    !isValidAccId
                  }
                >
                  {copyChatbotAcrossAccountAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      COPY
                    </>
                  ) : (
                    "COPY"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CopyChatbotModal;
