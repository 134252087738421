import ApiBaseService from "./ApiBaseService.js";

class UserService {
  static getUser() {
    return ApiBaseService.request(`/users/me`, "GET");
  }

  static updateUser([data]) {
    return ApiBaseService.request(`/users/me`, "PATCH", data);
  }

  static getUserAccounts() {
    return ApiBaseService.request(`/users/me/accounts`, "GET");
  }

  static getUserRoles() {
    return ApiBaseService.request(`/users/roles`, "GET");
  }
}

export default UserService;
