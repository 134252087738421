import React, { useState } from "react";

import KokiNav from "../layouts/nav/index.js";
import ScrollToTop from "../pages/ScrollToTop";
import TestBar from "../utils/TestBar.js";

import { Outlet } from "react-router-dom";

const Home = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const currentURL = window.location.host;
  const isTestMode = currentURL.includes("dev");

  const [activeEvent, setActiveEvent] = useState(!path);

  return (
    <>
      <div id="main-wrapper" className="show">
        {isTestMode ? <TestBar /> : null}
        <KokiNav onClick={() => setActiveEvent(!activeEvent)} />
        <div
          className={` ${
            !path && activeEvent ? "rightside-event" : ""
          } content-body`}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
};

export default Home;
