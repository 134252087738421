import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../../utils/InputErrorMessage";
import { useEffect } from "react";
import useAPI from "../../../../../hooks/useAPI";
import AdminService from "../../../../../services/AdminService";

const EditAnnModal = ({ show, showHideHandler, data }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const typeOptions = [
    { value: "Information", label: "Information" },
    { value: "Warning", label: "Warning" },
    { value: "Critical", label: "Critical" },
  ];

  /* APIs */
  const editAnnouncementAPI = useAPI(
    AdminService.updateAnnouncement,
    editAnnouncementHandler,
    true
  );

  function editAnnouncement(formData) {
    const updatedFormData = {
      ...formData,
      isActive: formData.isActive === "true",
    };
    editAnnouncementAPI.execute(`${data._id}`, updatedFormData);
  }

  /* API success handlers */
  function editAnnouncementHandler(response) {
    showHideHandler(false);
    reset();
    window.location.reload();
  }

  useEffect(() => {
    reset({
      type: data.type,
      message: data.message,
      isActive: String(data.isActive),
    });
  }, [data, reset]);

  return (
    <Modal
      show={show}
      onHide={() => {
        showHideHandler(false);
        reset();
      }}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Annnouncment</h5>
            <button
              type="button"
              onClick={() => {
                showHideHandler(false);
                reset();
              }}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(editAnnouncement)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="type">
                  Announcement Type
                </label>
                <select
                  className="form-control"
                  name="type"
                  id="type"
                  {...register("type", {
                    required: "Announcement type is required",
                  })}
                  defaultValue={data.type}
                >
                  {typeOptions.map((typeOption, index) => (
                    <option key={index} value={typeOption.value}>
                      {typeOption.label}
                    </option>
                  ))}
                </select>
                <InputErrorMessage name="type" errors={errors} />

                <label className="text-black font-w500" htmlFor="message">
                  Announcement Message
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="message"
                  id="message"
                  {...register("message", {
                    required: "Announcement message is required",
                    maxLength: {
                      value: 300,
                      message:
                        "Announcement message should not exceed 300 characters",
                    },
                  })}
                  defaultValue={data.message}
                  maxLength={300}
                />
                <InputErrorMessage name="message" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="isActive">
                  Status
                </label>
                <select
                  className="form-control"
                  name="isActive"
                  id="isActive"
                  {...register("isActive", {
                    required: "Status is required",
                  })}
                  defaultValue={String(data.isActive)}
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
                <InputErrorMessage name="isActive" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={editAnnouncementAPI.status === "pending"}
                >
                  {editAnnouncementAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      Edit
                    </>
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditAnnModal;
