import ApiBaseService from "./ApiBaseService";

class AccountService {
  static getUserAccount() {
    return ApiBaseService.request("/accounts", "GET");
  }

  static updateAccountName([data]) {
    return ApiBaseService.request("/accounts/name", "PATCH", data);
  }

  static getAccountUsers() {
    return ApiBaseService.request("/accounts/users", "GET");
  }

  static addAccountUser([data]) {
    return ApiBaseService.request("/accounts/users", "POST", data);
  }

  static updateAccountUser([userId, data]) {
    return ApiBaseService.request(`/accounts/users/${userId}`, "PATCH", data);
  }

  static removeAccountUser([userId]) {
    return ApiBaseService.request(`/accounts/users/${userId}`, "DELETE");
  }
}

export default AccountService;
