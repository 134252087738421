import ApiBaseService from "./ApiBaseService";

class AdminService {
  static getDashboardStats() {
    return ApiBaseService.request("/control/dashboard-stats", "GET");
  }

  static getAccounts([page, limit = 10]) {
    let query = `?page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/accounts" + query, "GET");
  }

  static getAccountsBySearch([search, page, limit = 10]) {
    let query = `?search=${search}&page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/accounts" + query, "GET");
  }

  static getAccountsByUserId([userId = null]) {
    let query = "";
    if (userId) query = `?userId=${userId}`;
    return ApiBaseService.request("/control/accounts" + query, "GET");
  }

  static getUsers([page, limit = 10]) {
    let query = `?page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/users" + query, "GET");
  }

  static getUsersByUserId([userId = null]) {
    let query = "";
    if (userId) query = `?userId=${userId}`;
    return ApiBaseService.request("/control/users" + query, "GET");
  }

  static getUsersBySearch([search, page, limit = 10]) {
    let query = `?search=${search}&page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/users" + query, "GET");
  }

  static getAccountUsage([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/usage`,
      "GET"
    );
  }

  static requestAccountAccess([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/request-access`,
      "POST"
    );
  }

  static suspendAccount([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/suspend`,
      "POST"
    );
  }

  static unsuspendAccount([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/unsuspend`,
      "POST"
    );
  }

  static transferAccountOwnership([accountId, data]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/ownership`,
      "POST",
      data
    );
  }

  static deleteAccount([accountId]) {
    return ApiBaseService.request(`/control/accounts/${accountId}`, "DELETE");
  }

  static getAccountProjects([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/projects`,
      "GET"
    );
  }

  static getAccountChatbots([accountId]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/chatbots`,
      "GET"
    );
  }

  static copyChatbotAcrossAccount([accountId, chatbotId, data]) {
    return ApiBaseService.request(
      `/control/accounts/${accountId}/chatbots/${chatbotId}/copy`,
      "POST",
      data
    );
  }

  static blockUser([userId]) {
    return ApiBaseService.request(`/control/users/${userId}/block`, "POST");
  }

  static unblockUser([userId]) {
    return ApiBaseService.request(`/control/users/${userId}/unblock`, "POST");
  }

  static getSubscriptions([page, limit = 10]) {
    let query = `?page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/subscriptions" + query, "GET");
  }

  static getSubscriptionsBySearch([search, page, limit = 10]) {
    let query = `?search=${search}&page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/subscriptions" + query, "GET");
  }

  static getAllSubscriptionsPlans() {
    return ApiBaseService.request("control/subscriptions/plans", "GET");
  }

  static modifySubscription([subscriptionId, data]) {
    return ApiBaseService.request(
      `/control/subscriptions/${subscriptionId}/modify`,
      "POST",
      data
    );
  }

  static cancelSubscription([subscriptionId]) {
    return ApiBaseService.request(
      `/control/subscriptions/${subscriptionId}/cancel`,
      "POST"
    );
  }

  static addEnterpriseSubscription([data]) {
    return ApiBaseService.request(
      `/control/subscriptions/enterprise`,
      "POST",
      data
    );
  }

  static getSystemUserRoles() {
    return ApiBaseService.request(`/control/users/roles`, "GET");
  }

  static getTransactions([checkpoint, next, limit = 10]) {
    let query = `?checkpoint=${checkpoint}&next=${next}&limit=${limit}`;
    return ApiBaseService.request("/control/transactions" + query, "GET");
  }

  // Announcements Routes

  static getAnnouncements([page, limit = 10]) {
    let query = `?page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/announcements" + query, "GET");
  }

  static getAnnouncementsBySearch([search, page, limit = 10]) {
    let query = `?search=${search}&page=${page}&limit=${limit}`;
    return ApiBaseService.request("/control/announcements" + query, "GET");
  }

  static createAnnouncement([data]) {
    return ApiBaseService.request("/control/announcements", "POST", data);
  }

  static updateAnnouncement([announcementId, data]) {
    return ApiBaseService.request(
      `/control/announcements/${announcementId}`,
      "PATCH",
      data
    );
  }

  static deleteAnnouncement([announcementId]) {
    return ApiBaseService.request(
      `/control/announcements/${announcementId}`,
      "DELETE"
    );
  }
}

export default AdminService;
