import { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";

import useAPI from "../../../../hooks/useAPI";
import AccountService from "../../../../services/AccountService";
import { ReactComponent as KebabIcon } from "../../../../images/kebab.svg";
import ConfirmActionModal from "../../../utils/Modals/ConfirmActionModal";
import EditUserModal from "./EditUserModal";
import withState from "../../../utils/withState";

const UsersList = ({ userRoles, reduxState }) => {
  const [editUserModalData, setEditUserModalData] = useState();
  const [removeUserModalData, setRemoveUserModalData] = useState();

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const [accountUsers, setAccountUsers] = useState([]);

  const getAccountUsersAPI = useAPI(
    AccountService.getAccountUsers,
    getAccountUsersHandler
  );

  const removeAccountUserAPI = useAPI(
    AccountService.removeAccountUser,
    removeAccountUserHandler
  );

  function getAccountUsers() {
    getAccountUsersAPI.execute();
  }

  function removeAccountUser(userId) {
    removeAccountUserAPI.execute(userId);
  }

  useEffect(() => {
    getAccountUsers();
  }, []);

  function getAccountUsersHandler(response) {
    setAccountUsers(response.data);
  }

  function removeAccountUserHandler(response) {
    setShowRemoveUserModal(false);
    getAccountUsers();
  }

  return (
    <>
      {getAccountUsersAPI.status === "pending" ? (
        <LinearProgress color="inherit" />
      ) : (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <strong>NAME</strong>
              </th>
              <th>
                <strong>EMAIL</strong>
              </th>
              <th>
                <strong>ROLE</strong>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accountUsers.map((accountUser, index) => {
              return (
                <tr key={index}>
                  <td>
                    {accountUser.user.fullName}
                    <span className="ml-2">
                      {accountUser.isOwner ? (
                        <Badge variant="light badge-rounded badge-xs">
                          Owner
                        </Badge>
                      ) : null}
                    </span>
                  </td>
                  <td>{accountUser.user.email}</td>
                  <td>{accountUser.role.name}</td>
                  <td>
                    {accountUser.isOwner ||
                    reduxState.authUser._id === accountUser.user._id ? null : (
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btn sharp btn-primary tp-btn i-false"
                          data-toggle="dropdown"
                        >
                          <KebabIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setEditUserModalData(accountUser);
                              setShowEditUserModal(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setRemoveUserModalData(accountUser.user._id);
                              setShowRemoveUserModal(true);
                            }}
                          >
                            Remove
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <EditUserModal
        show={showEditUserModal}
        showHideHandler={setShowEditUserModal}
        data={editUserModalData}
        userRoles={userRoles}
      />
      <ConfirmActionModal
        title="Confirm removing the user?"
        description="This user will no longer be able to the access data and be member of the account."
        show={showRemoveUserModal}
        confirmActionHandler={() => {
          removeAccountUser(removeUserModalData);
        }}
        isActionPending={removeAccountUserAPI.status === "pending"}
        showHideHandler={setShowRemoveUserModal}
      />
    </>
  );
};

export default withState(UsersList);
