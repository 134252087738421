import React, { useEffect, useState, useRef } from "react";
import { Card, Dropdown, Tab, Nav } from "react-bootstrap";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import ConfirmActionModal from "../../../utils/Modals/ConfirmActionModal";

import useAPI from "../../../../hooks/useAPI";
import TemplateService from "../../../../services/TemplateService";

const svg1 = (
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <circle fill="#000000" cx="5" cy="12" r="2" />
      <circle fill="#000000" cx="12" cy="12" r="2" />
      <circle fill="#000000" cx="19" cy="12" r="2" />
    </g>
  </svg>
);

const TemplateDetail = () => {
  let navigate = useNavigate();

  const { chatbotId } = useParams();

  const chatbotNameInput = useRef(null);

  const [chatbotDetails, setTemplateDetails] = useState({
    name: "",
    project: { name: "" },
    generation: {
      status: "",
    },
  });

  const [isEditingTemplateName, setIsEditingTemplateName] = useState(false);

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);

  const getTemplateAPI = useAPI(
    TemplateService.getTemplate,
    getTemplateHandler
  );

  const updateTemplateAPI = useAPI(
    TemplateService.updateTemplate,
    updateTemplateHandler
  );

  const deleteTemplateAPI = useAPI(
    TemplateService.deleteTemplate,
    deleteTemplateHandler
  );

  function getTemplate() {
    getTemplateAPI.execute(chatbotId);
  }

  function updateTemplate() {
    updateTemplateAPI.execute(chatbotId, {
      name: chatbotNameInput.current.value,
    });
  }

  function deleteTemplate() {
    deleteTemplateAPI.execute(chatbotId);
  }

  const handleEnter = () => {
    updateTemplate();
    setIsEditingTemplateName(false);
  };

  const handleEsc = () => {
    setIsEditingTemplateName(false);
  };

  useEffect(() => {
    getTemplate();
  }, []);

  /* API success handlers */

  function getTemplateHandler(response) {
    setTemplateDetails(response.data);
  }

  function updateTemplateHandler(response) {
    setTemplateDetails(response.data);
  }

  function deleteTemplateHandler(response) {
    navigate("/management/templates");
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            {isEditingTemplateName ? (
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={chatbotDetails.name}
                  ref={chatbotNameInput}
                  maxLength={30}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleEnter();
                    } else if (event.key === "Escape") {
                      handleEsc();
                    }
                  }}
                />
                <IconButton
                  aria-label="done"
                  size="small"
                  onClick={() => {
                    updateTemplate();
                    setIsEditingTemplateName(false);
                  }}
                >
                  <DoneIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => {
                    setIsEditingTemplateName(false);
                  }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </div>
            ) : (
              <>
                <span>{chatbotDetails.name}</span>
                {"    "}
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => {
                    setIsEditingTemplateName(true);
                  }}
                >
                  <ModeEditIcon fontSize="inherit" />
                </IconButton>
              </>
            )}
          </Card.Title>

          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              className="light sharp icon-false"
            >
              {svg1}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setShowDeleteTemplateModal(true);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Card.Header>
        <Card.Body>
          <Tab.Container defaultActiveKey="knowledgeBase">
            <Nav as="ul" className="nav-pills mb-4 light">
              <Nav.Item as="li">
                <Nav.Link eventKey="knowledgeBase">Knowledge Base</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="knowledgeBase">
                <KnowledgeBase />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
      <ConfirmActionModal
        title="Confirm template delete?"
        description="All associated knowledge bases will also be deleted."
        show={showDeleteTemplateModal}
        confirmActionHandler={() => {
          deleteTemplate();
        }}
        isActionPending={deleteTemplateAPI.status === "pending"}
        showHideHandler={setShowDeleteTemplateModal}
      />
    </>
  );
};

export default TemplateDetail;
