import { useEffect, useState } from "react";

/// Link
import { Link, useLocation, useNavigate } from "react-router-dom";

import RegexUtils from "../../../utils/Regex";
import withState from "../../utils/withState";

const menu = [
  {
    label: "Dashboard",
    icon: "flaticon-381-knob-1",
    to: "/",
  },
  {
    label: "Administration",
    icon: "flaticon-381-controls-1",
    subMenu: [
      {
        label: "Accounts",
        to: "/administration/accounts",
      },
      {
        label: "Users",
        to: "/administration/users",
      },
      {
        label: "Subscriptions",
        to: "/administration/subscriptions",
      },
      {
        label: "Transactions",
        to: "/administration/transactions",
      },
    ],
  },
  {
    label: "Management",
    icon: "flaticon-381-controls-3",
    subMenu: [
      {
        label: "Templates",
        to: "/management/templates",
      },
      {
        label: "Assistants",
        to: "/management/assistants",
      },
      {
        label: "Announcements",
        to: "/management/announcements",
      },
    ],
  },
  {
    label: "Account",
    icon: "flaticon-381-user-7",
    subMenu: [
      {
        label: "Users",
        to: "/account/system-users",
      },
      {
        label: "Settings",
        to: "/account/settings",
      },
    ],
  },
];

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    // sidebar open/close
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);

    // sidebar icon Heart blast
    const handleheartBlast = document.querySelector(".heart");

    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }

    handleheartBlast.addEventListener("click", heartBlast);

    // Cleanup event listeners on unmount
    return () => {
      btn.removeEventListener("click", toggleFunc);
      handleheartBlast.removeEventListener("click", heartBlast);
    };
  }, []); // Empty dependency array to mimic componentDidMount and componentWillUnmount

  useEffect(() => {
    let paths = pathname.split("/");
    if (RegexUtils.DIGIT.test(paths[paths.length - 1])) {
      // if endpoint is "id", then pop last element to use the previous param
      paths.pop();
    }
    paths = paths[paths.length - 1];

    setPath(paths);
  }, [pathname]);

  useEffect(() => {
    const menuItems = document.querySelectorAll(".metismenu > li"); // selects the top level children

    addMenuItemClickListeners(menuItems);

    const subMenuItems = document.querySelectorAll(".metismenu ul > li"); // selects the sub list level children

    addSubMenuItemClickListeners(subMenuItems);

    return () => {
      menuItems.removeEventListener("click");
      subMenuItems.removeEventListener("click");
    };
  }, []);

  function addMenuItemClickListeners(menuItems) {
    menuItems.forEach((item) => {
      item.addEventListener("click", (event) => {
        // Check if the item clicked is having sub-items
        let isSubMenu = item.querySelector("ul") !== null;

        if (isSubMenu) {
          let icon = item.querySelector("a");
          let subMenu = item.querySelector("ul");
          let isCollapsed = subMenu.classList.contains("mm-collapse");
          if (isCollapsed) {
            subMenu.classList.add("mm-show");
            subMenu.classList.remove("mm-collapse");
            icon.classList.remove("mm-collapsed");
            icon.setAttribute("aria-expanded", true);
          } else {
            subMenu.classList.remove("mm-show");
            subMenu.classList.add("mm-collapse");
            icon.classList.add("mm-collapsed");
            item.classList.remove("mm-active");
            icon.setAttribute("aria-expanded", false);
          }
        } else {
          item.classList.add("mm-active");
          menuItems.forEach((i) => i.classList.remove("mm-active"));
        }
      });
    });
  }

  function addSubMenuItemClickListeners(subMenuItems) {
    subMenuItems.forEach((item) => {
      item.addEventListener("click", (event) => {
        event.stopPropagation();
        event.preventDefault();
        navigate(event.target.getAttribute("href"));
      });
    });
  }

  return (
    <div className="dlabnav">
      <div className="dlabnav-scroll d-flex flex-column flex-nowrap justify-content-between">
        <div className="mm-wrapper">
          <ul className="metismenu">
            {menu.map((menuItem, index) => {
              let isActive = false;
              let isSubMenu = menuItem.hasOwnProperty("subMenu");

              if (isSubMenu) {
                let subItemsEndPaths = menuItem.subMenu.map((subMenuItem) => {
                  let splitPaths = subMenuItem.to.split("/");
                  return splitPaths[splitPaths.length - 1];
                });

                isActive = subItemsEndPaths.includes(path);
              } else {
                let splitPaths = menuItem.to.split("/");
                let endPath = splitPaths[splitPaths.length - 1];
                isActive = path === endPath;
              }

              return (
                <li
                  className={`${isActive ? "mm-active" : ""}`}
                  key={`menuItem-${index}`}
                >
                  <Link
                    className={`ai-icon ${isSubMenu ? "has-arrow" : ""}`}
                    to={menuItem.to}
                  >
                    <i className={menuItem.icon}></i>
                    <span className="nav-text">{menuItem.label}</span>
                  </Link>
                  {isSubMenu ? (
                    <ul className={`${isActive ? "mm-show" : "mm-collapse"}`}>
                      {menuItem.subMenu.map((subMenuItem, subIndex) => {
                        let splitPaths = subMenuItem.to.split("/");
                        let endPath = splitPaths[splitPaths.length - 1];
                        return (
                          <li key={`menuItem-${index}-subMenuItem-${subIndex}`}>
                            <Link
                              className={`${
                                path === endPath ? "mm-active" : ""
                              }`}
                              to={subMenuItem.to}
                            >
                              {subMenuItem.label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="copyright">
          <p>
            Made with <span className="heart"></span> by Apnatomy
          </p>
        </div>
      </div>
    </div>
  );
};

export default withState(SideBar);
