import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LinearProgress } from "@mui/material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import ConfirmActionModal from "../../../utils/Modals/ConfirmActionModal";
import InputErrorMessage from "../../../utils/InputErrorMessage";
import useAPI from "../../../../hooks/useAPI";
import AssistantService from "../../../../services/AssistantService";

const Assistant = () => {
  const [createAssistantModalShow, setCreateAssistantModalShow] =
    useState(false);
  const [assistantDetailModalShow, setAssistantDetailModalShow] =
    useState(false);
  const [assistantDetailModalData, setAssistantDetailModalData] = useState({});

  const [assistants, setAssistants] = useState([]);

  const getAssistantsAPI = useAPI(
    AssistantService.getAssistants,
    getAssistantsHandler,
    false,
    true
  );

  function handleAssistantClick(assistant) {
    setAssistantDetailModalData({ ...assistant });
    setAssistantDetailModalShow(true);
  }

  /* API success handlers */

  function getAssistantsHandler(response) {
    setAssistants(response.data);
  }

  return (
    <>
      <div className="page-titles mb-1">
        <div className="d-flex justify-content-end flex-wrap">
          <Link
            onClick={() => {
              setCreateAssistantModalShow(true);
            }}
            className="btn btn-primary rounded"
          >
            New Assistant
          </Link>
        </div>
      </div>
      <div>
        {getAssistantsAPI.status === "pending" ? (
          <LinearProgress color="inherit" />
        ) : assistants.length === 0 ? (
          <div className="my-5">
            <p className="text-center">No assistants created</p>
          </div>
        ) : null}
        <div className="row">
          {assistants.map((row, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6 col-sm-6">
                <div
                  className="card overflow-hidden clickable"
                  onClick={() => handleAssistantClick(row)}
                >
                  <div className="card-body">
                    <div className="text-center">
                      <h3 className="mb-0">
                        {row.isDefault ? (
                          <StarBorderRoundedIcon
                            color="primary"
                            className="mb-1"
                          />
                        ) : null}{" "}
                        {row.name}{" "}
                      </h3>
                    </div>
                    <div className="text-center mt-2">
                      <small>
                        Used by <b>{row.usedBy}</b> Chatbots
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CreateAssistantModal
        show={createAssistantModalShow}
        showHideHandler={setCreateAssistantModalShow}
      />
      <AssistantDetailModal
        show={assistantDetailModalShow}
        showHideHandler={setAssistantDetailModalShow}
        data={assistantDetailModalData}
      />
    </>
  );
};

const CreateAssistantModal = ({ show, showHideHandler }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const createAssistantAPI = useAPI(
    AssistantService.createAssistant,
    createAssistantHandler
  );

  function createAssistant(formData) {
    createAssistantAPI.execute(formData);
  }

  /* API success handlers */

  function createAssistantHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <Modal
      show={show}
      onHide={() => showHideHandler(false)}
      className="modal fade"
    >
      <div role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Assistant</h5>
            <button
              type="button"
              onClick={() => showHideHandler(false)}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>{" "}
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(createAssistant)}>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="nameInput">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="nameInput"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 1,
                      message: "Name should have at least 1 char",
                    },
                    maxLength: {
                      value: 30,
                      message: "Name should have at most 30 chars",
                    },
                  })}
                />
                <InputErrorMessage name="name" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="styleInput">
                  Style
                </label>
                <textarea
                  cols="30"
                  rows="2"
                  className="form-control bg-transparent"
                  placeholder="Specify the desired writing style"
                  name="style"
                  id="styleInput"
                  {...register("style", {
                    required: "Style is required",
                    minLength: {
                      value: 20,
                      message: "Style should have at least 20 chars",
                    },
                    maxLength: {
                      value: 300,
                      message: "Style should have at most 300 chars",
                    },
                  })}
                />
                <small>
                  Example: Follow the style of a personal assistant.
                </small>
                <InputErrorMessage name="style" errors={errors} />
              </div>
              <div className="form-group">
                <label className="text-black font-w500" htmlFor="toneInput">
                  Tone
                </label>
                <textarea
                  cols="30"
                  rows="2"
                  className="form-control bg-transparent"
                  placeholder="Specify the tone of the response"
                  name="tone"
                  id="toneInput"
                  {...register("tone", {
                    required: "Tone is required",
                    minLength: {
                      value: 20,
                      message: "Tone should have at least 20 chars",
                    },
                    maxLength: {
                      value: 300,
                      message: "Tone should have at most 300 chars",
                    },
                  })}
                />
                <small>
                  Example: Be positive, empathetic, and compassionate.
                </small>
                <InputErrorMessage name="tone" errors={errors} />
              </div>
              <div className="form-group mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || createAssistantAPI.status === "pending"}
                >
                  {createAssistantAPI.status === "pending" ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm text-light mr-2"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                      CREATE
                    </>
                  ) : (
                    "CREATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const AssistantDetailModal = ({ show, showHideHandler, data }) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    reset,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const updateAssistantAPI = useAPI(
    AssistantService.updateAssistant,
    updateAssistantHandler
  );

  const deleteAssistantAPI = useAPI(
    AssistantService.deleteAssistant,
    deleteAssistantHandler
  );

  function updateAssistant(formData) {
    updateAssistantAPI.execute(data._id, formData);
  }

  function deleteAssistant() {
    deleteAssistantAPI.execute(data._id);
  }

  useEffect(() => {
    reset({
      ...data,
      ...data.behaviour,
    });
  }, [data]);

  /* API success handlers */

  function updateAssistantHandler(response) {
    setShowDeleteConfirmModal(false);
    window.location.reload();
  }

  function deleteAssistantHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => showHideHandler(false)}
        className="modal fade"
      >
        <div role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {data.isDefault ? (
                  <StarBorderRoundedIcon color="primary" className="mb-1" />
                ) : null}{" "}
                {data.name}{" "}
              </h5>
              <button
                type="button"
                onClick={() => showHideHandler(false)}
                className="close"
                data-dismiss="modal"
              >
                <span>&times;</span>{" "}
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(updateAssistant)}>
                <div className="form-group">
                  <label className="text-black font-w500" htmlFor="nameInput">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="nameInput"
                    {...register("name", {
                      required: "Name is required",
                      minLength: {
                        value: 1,
                        message: "Name should have at least 1 char",
                      },
                      maxLength: {
                        value: 30,
                        message: "Name should have at most 30 chars",
                      },
                    })}
                  />
                  <InputErrorMessage name="name" errors={errors} />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500" htmlFor="styleInput">
                    Style
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    className="form-control bg-transparent"
                    placeholder="Specify the desired writing style"
                    name="style"
                    id="styleInput"
                    {...register("style", {
                      required: "Style is required",
                      minLength: {
                        value: 20,
                        message: "Style should have at least 20 chars",
                      },
                      maxLength: {
                        value: 300,
                        message: "Style should have at most 300 chars",
                      },
                    })}
                  />
                  <small>
                    Example: Follow the style of a personal assistant.
                  </small>
                  <InputErrorMessage name="style" errors={errors} />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500" htmlFor="toneInput">
                    Tone
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    className="form-control bg-transparent"
                    placeholder="Specify the tone of the response"
                    name="tone"
                    id="toneInput"
                    {...register("tone", {
                      required: "Tone is required",
                      minLength: {
                        value: 20,
                        message: "Tone should have at least 20 chars",
                      },
                      maxLength: {
                        value: 300,
                        message: "Tone should have at most 300 chars",
                      },
                    })}
                  />
                  <small>
                    Example: Be positive, empathetic, and compassionate.
                  </small>
                  <InputErrorMessage name="tone" errors={errors} />
                </div>
                <div className="form-group mt-4 mb-0 d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      !isValid || updateAssistantAPI.status === "pending"
                    }
                  >
                    {updateAssistantAPI.status === "pending" ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm text-light mr-2"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                        UPDATE
                      </>
                    ) : (
                      "UPDATE"
                    )}
                  </button>
                  {data.isDefault ? null : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      disabled={deleteAssistantAPI.status === "pending"}
                      onClick={() => {
                        showHideHandler(false);
                        setShowDeleteConfirmModal(true);
                      }}
                    >
                      {deleteAssistantAPI.status === "pending" ? (
                        <>
                          <div
                            className="spinner-border spinner-border-sm text-light mr-2"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </div>
                          DELETE
                        </>
                      ) : (
                        "DELETE"
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmActionModal
        title="Confirm delete of assistant?"
        description="Any existing chatbots using this assistant will be automatically moved to the default assistant."
        show={showDeleteConfirmModal}
        confirmActionHandler={deleteAssistant}
        isActionPending={deleteAssistantAPI.status === "pending"}
        showHideHandler={setShowDeleteConfirmModal}
      />
    </>
  );
};

export default Assistant;
