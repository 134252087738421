import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import useAPI from "../../../../../hooks/useAPI";
import TemplateService from "../../../../../services/TemplateService";

const ExtractedContentModal = ({ show, showHideHandler, data }) => {
  if (Object.keys(data).length === 0) return null;
  if (
    data.knowledgeBase.resourceType !== "document" &&
    data.knowledgeBase.resourceType !== "webpage"
  )
    return null;

  const [isEditing, setIsEditing] = useState(false);

  const [content, setContent] = useState(data.knowledgeBase.extractedContent);
  const updateKnowledgeBaseContentAPI = useAPI(
    TemplateService.updateKnowledgeBaseContent,
    updateKnowledgeBaseContentHandler
  );

  function updateKnowledgeBaseContent() {
    updateKnowledgeBaseContentAPI.execute(
      data.chatbotId,
      data.knowledgeBase._id,
      {
        content,
      }
    );
  }

  function updateKnowledgeBaseContentHandler(response) {
    showHideHandler(false);
    window.location.reload();
  }

  useEffect(() => {
    setContent(data.knowledgeBase.extractedContent);
  }, [data.knowledgeBase.extractedContent]);
  return (
    <Modal
      className="fade bd-example-modal-lg modal-dialog-scrollable"
      show={show}
      onHide={() => showHideHandler(false)}
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Extracted Content</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => showHideHandler(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {isEditing ? (
          <div className="form-group">
            <textarea
              cols="30"
              rows="15"
              className="form-control bg-transparent"
              defaultValue={content}
              onKeyUp={(e) => {
                setContent(e.target.value);
              }}
            />
          </div>
        ) : (
          content
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <span>{content ? content.length : 0} characters</span>
        <div>
          {isEditing ? (
            <>
              <Button
                className="mr-2"
                onClick={() => {
                  setContent(data.knowledgeBase.extractedContent);
                  setIsEditing(false);
                }}
                variant="danger light"
              >
                Cancel
              </Button>
              {updateKnowledgeBaseContentAPI.status === "pending" ? (
                <button type="button" className="btn btn-primary" disabled>
                  <div
                    className="spinner-border spinner-border-sm text-light mr-2"
                    role="status"
                  >
                    <span className="visually-hidden"></span>
                  </div>
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    updateKnowledgeBaseContent();
                  }}
                >
                  Save
                </button>
              )}
            </>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setIsEditing(true)}
            >
              Edit Content
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtractedContentModal;
