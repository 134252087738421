import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import illustration from "../../../images/forgot-ill.png";
import RegexUtils from "../../../utils/Regex";
import InputErrorMessage from "../../utils/InputErrorMessage";
import SnackbarConstants from "../../../constants/Snackbar";
import AuthService from "../../../services/AuthService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [forgotPasswordState, setForgotPasswordState] = useState(1);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  async function handleFormSubmit(data) {
    setIsFormProcessing(true);
    try {
      if (forgotPasswordState === 1) {
        const captchaToken = await executeRecaptcha("otp_request");
        const response = await AuthService.sendOTP(
          {
            email: data.email,
          },
          captchaToken
        );
        setTransactionId(response.data.transactionId);
        setForgotPasswordState(2);
        enqueueSnackbar(response.message, SnackbarConstants.success);
      } else if (forgotPasswordState === 2) {
        const captchaToken = await executeRecaptcha("otp_verify");
        const response = await AuthService.verifyOTP(
          {
            email: data.email,
            otp: data.otp,
            transactionId,
          },
          captchaToken
        );
        setTransactionId(response.data.transactionId);
        setForgotPasswordState(3);
        enqueueSnackbar(response.message, SnackbarConstants.success);
      } else {
        const captchaToken = await executeRecaptcha("forgot_password");
        const response = await AuthService.forgotPassword(
          {
            ...data,
            transactionId,
          },
          captchaToken
        );
        enqueueSnackbar(response.message, SnackbarConstants.success);
        navigate("/auth");
      }
    } catch (error) {
      console.log(error);
      let errorData = error.data;
      enqueueSnackbar(errorData.message, SnackbarConstants.error);
      let errors = errorData.errors;
      if (errors) {
        errors.forEach((item, i) => {
          enqueueSnackbar(item, SnackbarConstants.error);
        });
      }
    }
    setIsFormProcessing(false);
  }

  async function resendOTP(e) {
    e.preventDefault();
    const captchaToken = await executeRecaptcha("otp_request");
    const response = await AuthService.sendOTP(
      {
        email: getValues("email"),
      },
      captchaToken
    );
    setTransactionId(response.data.transactionId);
    enqueueSnackbar(response.message, SnackbarConstants.success);
  }

  return (
    <div className="row justify-content-center align-items-center h-80">
      <div className="col col-12 col-lg-6">
        <div className="text-center mb-3">
          <img
            src={illustration}
            alt="illustration"
            className="auth-illustration"
          />
        </div>
      </div>
      <div className="col col-12 col-lg-6">
        <div className="authentication-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <h3 className="text-center mb-4">Reset your password</h3>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="form-group">
                    <label className="mb-1" htmlFor="emailInput">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="hello@example.com"
                      name="email"
                      id="emailInput"
                      aria-describedby="emailHelp"
                      autoComplete="username"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegexUtils.EMAIL,
                          message: "Email is invalid",
                        },
                      })}
                      disabled={forgotPasswordState !== 1}
                    />
                    <InputErrorMessage name="email" errors={errors} />
                  </div>
                  {forgotPasswordState === 2 ? (
                    <div className="form-group">
                      <label className="mb-1" htmlFor="otpInput">
                        <strong>OTP</strong>
                        <small> (One Time Password)</small>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="otp"
                        id="otpInput"
                        {...register("otp", {
                          required: "OTP is required",
                          pattern: {
                            value: RegexUtils.OTP,
                            message: "OTP is invalid",
                          },
                        })}
                      />
                      <InputErrorMessage name="otp" errors={errors} />
                      <small>
                        <a href="" onClick={resendOTP}>
                          Resend OTP
                        </a>
                      </small>
                    </div>
                  ) : null}
                  {forgotPasswordState === 3 ? (
                    <>
                      <div className="form-group">
                        <label className="mb-1" htmlFor="newPasswordInput">
                          <strong>New Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="newPassword"
                          id="newPasswordInput"
                          {...register("newPassword", {
                            required: "New Password is required",
                            pattern: {
                              value: RegexUtils.PASSWORD,
                              message: "New Password is invalid",
                            },
                          })}
                        />
                        <InputErrorMessage name="newPassword" errors={errors} />
                      </div>
                      <div className="form-group">
                        <label className="mb-1" htmlFor="confirmPasswordInput">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          id="confirmPasswordInput"
                          {...register("confirmPassword", {
                            required: "Confirm Password is required",
                            pattern: {
                              value: RegexUtils.PASSWORD,
                              message: "Confirm Password is invalid",
                            },
                          })}
                        />
                        <InputErrorMessage
                          name="confirmPassword"
                          errors={errors}
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="text-center mt-4">
                    {isFormProcessing ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled
                      >
                        <div
                          className="spinner-border spinner-border-sm text-light mr-2"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                        {getSubmitButtonText()}
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        {getSubmitButtonText()}
                      </button>
                    )}
                  </div>
                </form>
                <div className="new-account mt-3">
                  <p>
                    Remember your password? <Link to="/auth">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function getSubmitButtonText() {
    if (forgotPasswordState === 1) return "SUBMIT";
    if (forgotPasswordState === 2) return "VERIFY";
    return "RESET";
  }
};

export default ForgotPassword;
