import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmActionModal = ({
  title,
  description,
  show,
  confirmActionHandler,
  isActionPending = false,
  showHideHandler,
}) => {
  return (
    <Modal className="fade" show={show} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button
          onClick={() => showHideHandler(false)}
          variant=""
          className="close"
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p className="m-0">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => showHideHandler(false)} variant="primary light">
          Cancel
        </Button>
        {isActionPending ? (
          <button type="button" className="btn btn-danger" disabled>
            <div
              className="spinner-border spinner-border-sm text-light mr-2"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
            CONFIRM
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-danger"
            onClick={confirmActionHandler}
          >
            CONFIRM
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmActionModal;
